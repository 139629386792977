// VITE_APP_OPENAI_API_KEY=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
// VITE_APP_ASSISTANTS_ENDPOINT=https://api.openai.com/v1/assistants/asst_qsi7tlL431JZFkuBm88QOj3z/messages

import { useState, useEffect, useRef } from 'react';
import './FindALawyer.css';
import { pollForNewMessages, createMatterIfReady } from './findALawyerUtil';
import logo from '../../assets/PoweredByPractist.webp';

const FindALawyer = () => {
  const [userMessage, setUserMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const assistantId = 'asst_qsi7tlL431JZFkuBm88QOj3z';
  const [isLoading, setIsLoading] = useState(false);
  const [isMatterCreated, setIsMatterCreated] = useState(false);
  const messagesEndRef = useRef(null);

  // Update this URL to the endpoint of your Firebase Cloud Function
  const proxyUrl = 'https://us-central1-breakfast-8ac2d.cloudfunctions.net/openAIProxy';

  // Function to create a new thread
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const createThread = async () => {
      try {
        const response = await fetch(proxyUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            path: '/threads',
            method: 'POST',
            headers: {
              'OpenAI-Beta': 'assistants=v1',
            },
          }),
          signal: signal,
        });

        const data = await response.json();
        if (data) {
          setThreadId(data.id);
        }
      } catch (error) {
        if (error.name == 'AbortError') {
          // This request was cancelled because the component was unmounted (`controller.abort()` was called).
          return;
        }
        console.error('Error creating thread:', error);
        captureException(error);
      }
    };

    createThread();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (!userMessage.trim() || !threadId) return;

    setIsLoading(true);

    try {
      // Send the user's message to the thread
      const postMessageResponse = await fetch(proxyUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          path: `/threads/${threadId}/messages`,
          method: 'POST',
          headers: {
            'OpenAI-Beta': 'assistants=v1',
          },
          data: { role: 'user', content: userMessage },
        }),
      });
      if (!postMessageResponse.ok) {
        throw new Error(
          `Failed to upload new user message; status: ${postMessageResponse.status}; statusText: ${postMessageResponse.statusText}`
        );
      }
      const postMessageBody = await postMessageResponse.json();
      const updatedConversation = [
        ...conversation,
        {
          id: postMessageBody.id,
          role: postMessageBody.role,
          content: postMessageBody.content[0]?.text?.value ?? '(no content)',
        },
      ];

      setConversation(updatedConversation);
      setUserMessage(''); // Clear the input field after sending

      // Create a run to process the thread with the assistant
      await fetch(proxyUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          path: `/threads/${threadId}/runs`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 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`,
            'OpenAI-Beta': 'assistants=v1',
          },
          data: {
            assistant_id: assistantId,
          },
        }),
      });

      const data = await pollForNewMessages(proxyUrl, threadId, updatedConversation);
      const newConversation = data.reverse().map((msg) => ({
        id: msg.id,
        role: msg.role,
        content: msg.content[0]?.text?.value ?? '(no content)',
      }));

      if (!isMatterCreated) {
        const didCreate = await createMatterIfReady(newConversation);
        setIsMatterCreated(didCreate);
      }

      setConversation(newConversation);
    } catch (error) {
      console.error('Error:', error);
      captureException(error);
      setConversation((prev) => [
        ...prev,
        {
          id: 'error',
          role: 'assistant',
          content: 'Sorry, there was an error processing your request.',
        },
      ]);
    }
    setIsLoading(false);
  };

  // Define the function to scroll to the bottom of the messages container
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='chat-container'>
      <div className='chat-header'>
        <h2>Tap a Button. Get a Lawyer.</h2>
        <img src={logo} alt='Powered by Practist' className='chat-logo' />
      </div>

      <div className='messages-container'>
        {conversation.map((msg, index) => (
          <div key={msg.id} className={`message ${msg.role === 'assistant' ? 'FindALawyer' : 'Me'}`}>
            {msg.content}
          </div>
        ))}
        {isLoading && <div className='loading'>Working on response...</div>}
        <div ref={messagesEndRef} /> {/* Empty div to act as the scroll target */}
      </div>
      <div className='input-container'>
        <input
          type='text'
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(e)}
          placeholder='Type your message here...'
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default FindALawyer;
