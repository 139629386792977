/* import React from 'react';
import './PlusButtonDropdown.css';

const PlusButtonDropdown = ({ visible, onNewMatterClick, toggleDropdown }) => {
  const handleMenuItemClick = (callback) => {
    if (callback) {
      callback();
    }
    toggleDropdown();
  };

  return (
    <div className={`plus-button-dropdown ${visible ? 'visible' : ''}`}>
      <ul>
        <li onClick={() => handleMenuItemClick(onNewMatterClick)}>New Matter</li>
        <li onClick={() => handleMenuItemClick()}>Time Entry</li>
        <li onClick={() => handleMenuItemClick()}>Expense Entry</li>
        <li onClick={() => handleMenuItemClick()}>Task</li>
        <li onClick={() => handleMenuItemClick()}>Note</li>
      </ul>
    </div>
  );
};

export default PlusButtonDropdown; */

import React from 'react';
import './PlusButtonDropdown.css';

interface PlusButtonDropdownProps {
  visible: boolean;
  onNewMatterClick: () => void;
  onNoteClick: () => void;
  onTimeClick: () => void;
  onTaskClick: () => void;
  onExpenseClick: () => void;
  toggleDropdown: () => void;
}

const PlusButtonDropdown = ({
  visible,
  onNewMatterClick,
  onNoteClick,
  onTimeClick,
  onTaskClick,
  onExpenseClick,
  toggleDropdown,
}: PlusButtonDropdownProps) => {
  const handleMenuItemClick = (callback: () => void) => {
    if (callback) {
      callback();
    }
    toggleDropdown();
  };

  return (
    <div className={`plus-button-dropdown ${visible ? 'visible' : ''}`}>
      <ul>
        <li onClick={() => handleMenuItemClick(onNewMatterClick)}>New Matter</li>
        <li onClick={() => handleMenuItemClick(onTimeClick)}>Time Entry</li>
        <li onClick={() => handleMenuItemClick(onExpenseClick)}>Expense Entry</li>
        <li onClick={() => handleMenuItemClick(onTaskClick)}>Task</li>
        <li onClick={() => handleMenuItemClick(onNoteClick)}>Note</li>
      </ul>
    </div>
  );
};

export default PlusButtonDropdown;
