import firebase from 'firebase/compat/app';
import Firestore = firebase.firestore.Firestore;
import DocumentReference = firebase.firestore.DocumentReference;
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;
import DocumentData = firebase.firestore.DocumentData;
import FieldPath = firebase.firestore.FieldPath;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;
import {
  ContactWithRoleData,
  EngagementLetterData,
  ExpenseEntryData,
  FinancialInstitutionData,
  FirmData,
  GeneratedInvoiceData,
  HumanData,
  InvoiceData,
  KnowledgeBasePageData,
  MatterData,
  MatterDocumentData,
  NoteData,
  OrganizationData,
  TaskData,
  TimeEntryData,
  TransactionData,
  UserData,
  PaySheetData,
} from './baseTypes';
import {
  contactWithRoleConverter,
  matterDocumentConverter,
  engagementLetterConverter,
  expenseEntryConverter,
  firmConverter,
  generatedInvoiceConverter,
  humanConverter,
  invoiceConverter,
  matterConverter,
  noteConverter,
  organizationConverter,
  taskConverter,
  timeEntryConverter,
  transactionConverter,
  userConverter,
  knowledgeBasePageConverter,
  financialInstitutionConverter,
} from './converters';

export class QueryBuilder {
  firmId: string;
  firestore: Firestore;

  constructor(firmId: string, firestore: Firestore) {
    this.firmId = firmId;
    this.firestore = firestore;
  }

  /**
   * `firms/{firmId}`
   */
  firm(): DocumentReference<FirmData> {
    return this.firestore
      .collection('firms')
      .withConverter(firmConverter)
      .doc(this.firmId);
  }

  /**
   * `firms/{firmId}/engagementLetters`
   */
  engagementLetters(
    where: WhereClause<EngagementLetterData>[] = [],
    orderBy: OrderByClause<EngagementLetterData>[] = []
  ): CollectionReference<EngagementLetterData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('engagementLetters')
        .withConverter(engagementLetterConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/engagementLetters/{letterId}`
   */
  engagementLetter(letterId: string): DocumentReference<EngagementLetterData> {
    this.assertExists(letterId, 'letterId is required');
    return this.engagementLetters().doc(letterId);
  }

  /**
   * `firms/{firmId}/expenseEntries`
   */
  expenseEntries(
    where: WhereClause<ExpenseEntryData>[] = [],
    orderBy: OrderByClause<ExpenseEntryData>[] = []
  ): CollectionReference<ExpenseEntryData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('expenseEntries')
        .withConverter(expenseEntryConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/expenseEntries/{entryId}`
   */
  expenseEntry(entryId: string): DocumentReference<ExpenseEntryData> {
    this.assertExists(entryId, 'entryId is required');
    return this.expenseEntries().doc(entryId);
  }

  /**
   * `firms/{firmId}/financialInstitutions`
   */
  financialInstitutions(
    where: WhereClause<FinancialInstitutionData>[] = [],
    orderBy: OrderByClause<FinancialInstitutionData>[] = []
  ): CollectionReference<FinancialInstitutionData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('financialInstitutions')
        .withConverter(financialInstitutionConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/financialInstitutions/{institutionId}`
   */
  financialInstitution(
    institutionId: string
  ): DocumentReference<FinancialInstitutionData> {
    this.assertExists(institutionId, 'institutionId is required');
    return this.financialInstitutions().doc(institutionId);
  }

  /**
   * `firms/{firmId}/generatedInvoices`
   */
  generatedInvoices(
    where: WhereClause<GeneratedInvoiceData>[] = [],
    orderBy: OrderByClause<GeneratedInvoiceData>[] = []
  ): CollectionReference<GeneratedInvoiceData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('generatedInvoices')
        .withConverter(generatedInvoiceConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/generatedInvoices/{invoiceId}`
   */
  generatedInvoice(invoiceId: string): DocumentReference<GeneratedInvoiceData> {
    this.assertExists(invoiceId, 'invoiceId is required');
    return this.generatedInvoices().doc(invoiceId);
  }

  /**
   * `firms/{firmId}/humans`
   */
  humans(
    where: WhereClause<HumanData>[] = [],
    orderBy: OrderByClause<HumanData>[] = []
  ): CollectionReference<HumanData> {
    return this.applyWhereAndOrderByClauses(
      this.firm().collection('humans').withConverter(humanConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/humans/{humanId}`
   */
  human(humanId: string): DocumentReference<HumanData> {
    this.assertExists(humanId, 'humanId is required');
    return this.humans().doc(humanId);
  }

  /**
   * `firms/{firmId}/invoices`
   */
  invoices(
    where: WhereClause<InvoiceData>[] = [],
    orderBy: OrderByClause<InvoiceData>[] = []
  ): CollectionReference<InvoiceData> {
    return this.applyWhereAndOrderByClauses(
      this.firm().collection('invoices').withConverter(invoiceConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/knowledgeBasePages`
   */
  knowledgeBasePages(
    where: WhereClause<KnowledgeBasePageData>[] = [],
    orderBy: OrderByClause<KnowledgeBasePageData>[] = []
  ): CollectionReference<KnowledgeBasePageData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('knowledgeBasePages')
        .withConverter(knowledgeBasePageConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/knowledgeBasePages/{pageId}`
   */
  knowledgeBasePage(pageId: string): DocumentReference<KnowledgeBasePageData> {
    this.assertExists(pageId, 'pageId is required');
    return this.knowledgeBasePages().doc(pageId);
  }

  /**
   * `firms/{firmId}/matters`
   */
  matters(
    where: WhereClause<MatterData>[] = [],
    orderBy: OrderByClause<MatterData>[] = []
  ): CollectionReference<MatterData> {
    return this.applyWhereAndOrderByClauses(
      this.firm().collection('matters').withConverter(matterConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/matters/{matterId}`
   */
  matter(matterId: string): DocumentReference<MatterData> {
    this.assertExists(matterId, 'matterId is required');
    return this.matters().doc(matterId);
  }

  /**
   * `firms/{firmId}/matters/{matterId}/contactsWithRoles`
   */
  matterContacts(
    matterId: string,
    where: WhereClause<ContactWithRoleData>[] = [],
    orderBy: OrderByClause<ContactWithRoleData>[] = []
  ): CollectionReference<ContactWithRoleData> {
    return this.applyWhereAndOrderByClauses(
      this.matter(matterId)
        .collection('contactsWithRoles')
        .withConverter(contactWithRoleConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/matters/{matterId}/documents`
   */
  matterDocuments(
    matterId: string,
    where: WhereClause<MatterDocumentData>[] = [],
    orderBy: OrderByClause<MatterDocumentData>[] = []
  ): CollectionReference<MatterDocumentData> {
    return this.applyWhereAndOrderByClauses(
      this.matter(matterId)
        .collection('documents')
        .withConverter(matterDocumentConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/matters/{matterId}/notes`
   */
  matterNotes(
    matterId: string,
    where: WhereClause<NoteData>[] = [],
    orderBy: OrderByClause<NoteData>[] = []
  ): CollectionReference<NoteData> {
    return this.applyWhereAndOrderByClauses(
      this.matter(matterId).collection('notes').withConverter(noteConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/organizations`
   */
  organizations(
    where: WhereClause<OrganizationData>[] = [],
    orderBy: OrderByClause<OrganizationData>[] = []
  ): CollectionReference<OrganizationData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('organizations')
        .withConverter(organizationConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/organizations/{organizationId}`
   */
  organization(organizationId: string): DocumentReference<OrganizationData> {
    this.assertExists(organizationId, 'organizationId is required');
    return this.organizations().doc(organizationId);
  }

  /**
   * `firms/{firmId}/tasks`
   */
  tasks(
    where: WhereClause<TaskData>[] = [],
    orderBy: OrderByClause<TaskData>[] = []
  ): CollectionReference<TaskData> {
    const collection = this.firm()
      .collection('tasks')
      .withConverter(taskConverter);
    return this.applyWhereAndOrderByClauses(collection, where, orderBy);
  }

  /**
   * `firms/{firmId}/tasks/{taskId}`
   */
  task(taskId: string): DocumentReference<TaskData> {
    this.assertExists(taskId, 'taskId is required');
    return this.tasks().doc(taskId);
  }

  /**
   * `firms/{firmId}/timeEntries`
   */
  timeEntries(
    where: WhereClause<TimeEntryData>[] = [],
    orderBy: OrderByClause<TimeEntryData>[] = []
  ): CollectionReference<TimeEntryData> {
    return this.applyWhereAndOrderByClauses(
      this.firm().collection('timeEntries').withConverter(timeEntryConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/timeEntries/{entryId}`
   */
  timeEntry(entryId: string): DocumentReference<TimeEntryData> {
    this.assertExists(entryId, 'entryId is required');
    return this.timeEntries().doc(entryId);
  }

  /**
   * `firms/{firmId}/transactions`
   */
  transactions(
    where: WhereClause<TransactionData>[] = [],
    orderBy: OrderByClause<TransactionData>[] = []
  ): CollectionReference<TransactionData> {
    return this.applyWhereAndOrderByClauses(
      this.firm()
        .collection('transactions')
        .withConverter(transactionConverter),
      where,
      orderBy
    );
  }

  transaction(id: string): DocumentReference<TransactionData> {
    this.assertExists(id, 'Transaction ID is required');
    return this.transactions().doc(id);
  }

  /**
   * `firms/{firmId}/users`
   */
  users(
    where: WhereClause<UserData>[] = [],
    orderBy: OrderByClause<UserData>[] = []
  ): CollectionReference<UserData> {
    return this.applyWhereAndOrderByClauses(
      this.firm().collection('users').withConverter(userConverter),
      where,
      orderBy
    );
  }

  /**
   * `firms/{firmId}/users/{userId}`
   */
  user(userId: string): DocumentReference<UserData> {
    this.assertExists(userId, 'userId is required');
    return this.users().doc(userId);
  }

  /**
   * `firms/{firmId}/paySheets`
   */
  paySheets(
    where: WhereClause<PaySheetData>[] = [],
    orderBy: OrderByClause<PaySheetData>[] = []
  ): Query<PaySheetData> {
    let query = this.firestore
      .collection(`firms/${this.firmId}/paySheets`)
      .withConverter<PaySheetData>({
        toFirestore: (data) => data as Document,
        fromFirestore: (snap) => snap.data() as PaySheetData,
      }) as Query<PaySheetData>;

    where.forEach(([fieldPath, opStr, value]) => {
      query = query.where(fieldPath, opStr, value) as Query<PaySheetData>;
    });

    orderBy.forEach(([fieldPath, directionStr]) => {
      query = query.orderBy(fieldPath, directionStr) as Query<PaySheetData>;
    });

    return query;
  }

  // Utilities

  applyWhereAndOrderByClauses<
    DataType extends DocumentData,
    QueryType extends Query<DataType>
  >(
    query: QueryType,
    where: WhereClause<DataType>[],
    orderBy: OrderByClause<DataType>[]
  ): QueryType {
    return this.applyOrderByClauses(
      this.applyWhereClauses(query, where),
      orderBy
    );
  }

  applyWhereClauses<
    DataType extends DocumentData,
    QueryType extends Query<DataType>
  >(query: QueryType, where?: WhereClause<DataType>[]): QueryType {
    return (where ?? []).reduce((aggregateQuery, clause) => {
      const [fieldPath, opStr, value] = clause;
      return aggregateQuery.where(fieldPath as string, opStr, value);
    }, query as Query) as QueryType;
  }

  applyOrderByClauses<
    DataType extends DocumentData,
    QueryType extends Query<DataType>
  >(query: QueryType, orderBy?: OrderByClause<DataType>[]): QueryType {
    return (orderBy ?? []).reduce((aggregateQuery, stmt) => {
      const [fieldPath, directionStr] = stmt;
      return aggregateQuery.orderBy(fieldPath as string, directionStr);
    }, query as Query) as QueryType;
  }

  assertExists(value: any, message: string) {
    if (!value) {
      throw new Error(message);
    }
  }
}

export type WhereClause<T> = [
  fieldPath: keyof T,
  opStr: WhereFilterOp,
  value: any
];

export type OrderByClause<T> = [
  fieldPath: keyof T,
  directionStr?: OrderByDirection
];
