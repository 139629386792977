import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

interface PdfInstance {
  pdf: jsPDF;
  pdfWidth: number;
  pdfHeight: number;
  pdfContentWidth: number;
  pdfContentHeight: number;
  position: number; // page's start position
  currentPage: number; // current page number of total pdf
  pageOfCurrentNode: number; // current page of current node
}

const pixelRatio = window.devicePixelRatio;

interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export async function createPdfFromHtml(
  collection: HTMLCollection,
  margin: Margin,
  numLinesBetweenElements: number = 1,
  onProgressChange: (progress: number) => void
): Promise<jsPDF> {
  const pdfInstance = makePdfInstance(margin);

  let currentElementIdx = 0;
  let currentYOffset = margin.top;
  while (currentElementIdx < collection.length) {
    const element = collection[currentElementIdx];
    if (currentYOffset === 0) {
      // This is a new page, so fill the background.
      pdfInstance.pdf.setFillColor(255, 255, 255);
      pdfInstance.pdf.rect(
        0,
        0,
        pdfInstance.pdfWidth,
        pdfInstance.pdfHeight,
        'F'
      ); // 'F' = 'fill'
    }

    const isLastElement = currentElementIdx === collection.length - 1;

    const result = await addElement(
      element as HTMLElement,
      pdfInstance,
      margin,
      currentYOffset,
      margin.top + pdfInstance.pdfContentHeight,
      isLastElement ? 0 : numLinesBetweenElements
    );

    if (result.resultType === 'outOfSpace') {
      // Add a new page but don't increment the element index.
      currentYOffset = margin.top;
      pdfInstance.pdf.addPage('LETTER', 'portrait');
    } else if (result.resultType == 'added') {
      // Stay on the current page and try to add the next element in the collection.
      currentYOffset = result.newYOffset!;
      currentElementIdx++;
    } else {
      throw new Error(
        `Unexpected result while rendering PDF: ${result.resultType}`
      );
    }
    onProgressChange(currentElementIdx / collection.length);
  }

  return pdfInstance.pdf;
}

function makePdfInstance(margin: any): PdfInstance {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'LETTER',
    hotfixes: ['px_scaling'],
  });
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const pdfContentWidth = pdfWidth - (margin.left + margin.right);
  const pdfContentHeight = pdfHeight - (margin.top + margin.bottom);
  const position = 0;
  const currentPage = 1;
  const pageOfCurrentNode = 1;
  return {
    pdf,
    pdfWidth,
    pdfHeight,
    pdfContentWidth,
    pdfContentHeight,
    position,
    currentPage,
    pageOfCurrentNode,
  };
}

interface AddElementResult {
  resultType: 'outOfSpace' | 'added';
  newYOffset?: number; // Only set if resultType is 'added'
}

async function addElement(
  element: HTMLElement,
  pdfInstance: PdfInstance,
  margin: Margin,
  currentYOffset: number,
  maxYOffset: number,
  numTrailingLines: number
): Promise<AddElementResult> {
  const { pdf, pdfContentWidth } = pdfInstance;

  const canvas = await html2canvas(element, { useCORS: true });
  canvas.getContext('2d', {
    willReadFrequently: true,
  });

  const { imageData, printWidth, printHeight } = convertCanvasToImageData({
    canvas,
    imageType: 'JPEG',
    imageQuality: '',
    autoResize: true,
    pdf,
    pdfContentWidth,
  });

  const printPlusBlankLinesHeight =
    printHeight + numTrailingLines * pdfInstance.pdf.getLineHeight();

  if (currentYOffset + printPlusBlankLinesHeight > maxYOffset) {
    return { resultType: 'outOfSpace' };
  }

  pdf.addImage(
    imageData, // imageData
    'JPEG', // format
    margin.left, // x
    currentYOffset, // y
    printWidth, // width
    printHeight, // height
    undefined,
    'SLOW'
  );

  return {
    resultType: 'added',
    newYOffset: currentYOffset + printPlusBlankLinesHeight,
  };
}

function convertCanvasToImageData({
  canvas,
  imageType,
  imageQuality,
  pdf,
  pdfContentWidth,
}: {
  canvas: HTMLCanvasElement;
  imageType: string;
  imageQuality: any;
  autoResize: boolean;
  pdf: jsPDF;
  pdfContentWidth: number;
}) {
  const imageData = canvas.toDataURL(imageType, imageQuality);
  const imgProps = pdf.getImageProperties(imageData);
  const scaledImgWidth = imgProps.width / pixelRatio;
  const printWidth =
    scaledImgWidth < pdfContentWidth ? scaledImgWidth : pdfContentWidth;
  const printHeight = (printWidth / imgProps.width) * imgProps.height;
  return {
    imageData,
    printWidth,
    printHeight,
  };
}
