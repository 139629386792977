//src/components/LandingPage/BodySection.js
import React from 'react';
import './LandingPage.css';

const BodySection = ({ title, subtitle, paragraph, image }) => (
  <div className="body-section">
    <div className="text-container">
      <h3>{title}</h3>
      <h2>{subtitle}</h2>
      <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
    </div>
    <img src={image} alt={title} className="body-image" />
  </div>
);

export default BodySection;