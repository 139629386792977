import firebase from 'firebase/compat/app';
import {
  ContactWithRoleData,
  EngagementLetterData,
  ExpenseEntryData,
  FinancialInstitutionData,
  FirmData,
  GeneratedInvoiceData,
  HumanData,
  InvoiceData,
  KnowledgeBasePageData,
  MatterData,
  MatterDocumentData,
  NoteData,
  OrganizationData,
  TaskData,
  TimeEntryData,
  TransactionData,
  UserData,
} from './baseTypes';
import { YearMonthDay } from './yearMonthDay';

export const generalConverter = <T>() => ({
  toFirestore(data: T): T {
    return cleanValueToFirestore(data);
  },

  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<T>): T {
    return cleanDataFromFirestore(snapshot.data());
  },
});

export const firmConverter = generalConverter<FirmData>();
export const noteConverter = generalConverter<NoteData>();
export const timeEntryConverter = generalConverter<TimeEntryData>();
export const expenseEntryConverter = generalConverter<ExpenseEntryData>();
export const financialInstitutionConverter =
  generalConverter<FinancialInstitutionData>();
export const taskConverter = generalConverter<TaskData>();
export const humanConverter = generalConverter<HumanData>();
export const organizationConverter = generalConverter<OrganizationData>();
export const knowledgeBasePageConverter =
  generalConverter<KnowledgeBasePageData>();
export const matterConverter = generalConverter<MatterData>();
export const userConverter = generalConverter<UserData>();
export const engagementLetterConverter =
  generalConverter<EngagementLetterData>();
export const generatedInvoiceConverter =
  generalConverter<GeneratedInvoiceData>();
export const invoiceConverter = generalConverter<InvoiceData>();
export const contactWithRoleConverter = generalConverter<ContactWithRoleData>();
export const matterDocumentConverter = generalConverter<MatterDocumentData>();
export const transactionConverter = generalConverter<TransactionData>();

export const cleanDataFromFirestore = <T>(data: T): T => {
  return cleanValueFromFirestore(data);
};

export const cleanDataToFirestore = <T>(data: T): T => {
  return cleanValueToFirestore(data);
};

// -----------------------------------------------------------------------------
// Private functions
// -----------------------------------------------------------------------------

const cleanValueFromFirestore = (value: any, level: number = 0): any => {
  if (value === null) {
    return undefined;
  }
  if (value instanceof firebase.firestore.Timestamp) {
    return value.toDate();
  }
  if (typeof value === 'string') {
    const ymd = YearMonthDay.fromFirestoreString(value);
    if (ymd) {
      return ymd;
    }
  }

  if (
    typeof value === 'object' &&
    value !== null &&
    !(value instanceof firebase.firestore.DocumentReference)
  ) {
    if (Array.isArray(value)) {
      return value.map((v) => cleanValueFromFirestore(v, level + 1));
    }
    const result: any = {};
    for (const key in value) {
      result[key] = cleanValueFromFirestore(value[key], level + 1);
    }
    return result;
  }
  return value;
};

const cleanValueToFirestore = (value: any, level: number = 0): any => {
  // Note: Our firestore settings include `ignoreUndefinedProperties`so we don't
  // need to convert undefined values to null.

  if (value instanceof YearMonthDay) {
    return value.toFirestoreString();
  }
  if (value instanceof Date) {
    // Javascript Dates are automatically converted to firestore Timestamps
    return value;
  }
  if (
    typeof value === 'object' &&
    value !== null &&
    !(value instanceof firebase.firestore.DocumentReference)
  ) {
    if (Array.isArray(value)) {
      return value.map((v) => cleanValueToFirestore(v, level + 1));
    }
    const result: any = {};
    for (const key in value) {
      result[key] = cleanValueToFirestore(value[key], level + 1);
    }
    return result;
  }
  return value;
};
