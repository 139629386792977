import { ActionIcon, createTheme, Paper, rem } from '@mantine/core';

export const theme = createTheme({
  fontFamily: 'Inter, sans-serif',
  // Default colors are defined at https://yeun.github.io/open-color/
  colors: {
    deepBlue: [
      '#eef3ff',
      '#dce4f5',
      '#b9c7e2',
      '#94a8d0',
      '#748dc1',
      '#5f7cb8',
      '#5474b4',
      '#44639f',
      '#39588f',
      '#2d4b81',
    ],
    // or replace default theme color
    blue: [
      '#eef3ff',
      '#dee2f2',
      '#bdc2de',
      '#98a0ca',
      '#7a84ba',
      '#6672b0',
      '#5c68ac',
      '#4c5897',
      '#424e88',
      '#364379',
    ],
  },

  shadows: {
    md: '1px 1px 3px rgba(0, 0, 0, .25)',
    xl: '5px 5px 3px rgba(0, 0, 0, .25)',
  },

  headings: {
    sizes: {
      h1: { fontSize: '1.75rem' },
      h2: { fontSize: '1.5rem' },
      h3: { fontSize: '1rem' },
    },
  },
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        color: 'gray',
        variant: 'subtle',
      },
      styles: {
        root: {
          // Override default 'button' styles.
          boxShadow: 'none',
          border: 'none',
          margin: 0,
        },
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        shadow: 'xs',
      },
    }),
  },
});
