import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebaseConfig';
import { loginRequest } from './msalConfig';
import { captureException } from '../sentry/sentry';

export async function loginPopup(pca: PublicClientApplication) {
  const makeUserFacingError = () => {
    return new Error(`Microsoft login failed unexpectedly. Please try again.`);
  };

  let authResult: AuthenticationResult | undefined;
  try {
    authResult = await pca.loginPopup(loginRequest);
  } catch (error: unknown) {
    console.error('Error from MSAL loginPopup:', error);
    captureException(error);
    throw makeUserFacingError();
  }

  if (!authResult) {
    const error = new Error(`No response from MSAL loginPopup`);
    console.error(error.message);
    captureException(error);
    throw makeUserFacingError();
  }

  if (!authResult.account) {
    const error = new Error(`MSAL loginPopup response is missing account info`);
    console.error(error.message);
    captureException(error);
    throw makeUserFacingError();
  }
  logEvent(analytics, 'msal_login_success', {
    msal_username: authResult.account.username,
    msal_tenant_id: authResult.account.tenantId,
    msal_environment: authResult.account.environment,
  });
  return authResult.account;
}

/**
 * Acquires an MSAL token for the specified account.
 *
 * @param pca The current user session's MSAL app.
 * @param account The account information for which to acquire the token.
 * @returns A promise that resolves to the authentication result.
 */
export async function acquireMsalToken(
  pca: PublicClientApplication,
  account?: AccountInfo
): Promise<AuthenticationResult> {
  if (!account) {
    throw new Error('Attempting to acquire MSAL token without account info');
  }
  const authedRequest = {
    ...loginRequest,
    account,
  };
  try {
    return await pca.acquireTokenSilent(authedRequest);
  } catch (error) {
    // call acquireTokenPopup in case of acquireTokenSilent failure
    // due to interaction required
    if (error instanceof InteractionRequiredAuthError) {
      return await pca.acquireTokenPopup(authedRequest);
    } else {
      captureException(error);
      throw error;
    }
  }
}

/**
 * Represents the response object returned after sending an email.
 */
export interface Response {
  data: any;
  status: number;
  statusText: string;
}

type AccessToken = string;

/**
 * Sends an email using the Microsoft Graph API.
 *
 * @param pca The current user session's MSAL app.
 * @param authInfo MSAL authorization information.
 * @param subject The subject of the email.
 * @param body The body of the email.
 * @param toEmailAddresses The email addresses of the recipients.
 * @param contentType The content type of the email (default is 'Text').
 * @returns A promise that resolves to the response object.
 *
 * If `authInfo` is an access token, then the token is included in the request
 * to send the email. Else, the provided account info is used to acquire a new
 * token first.
 */
export async function sendMsalEmail(
  pca: PublicClientApplication,
  authInfo: AccountInfo | AccessToken | undefined,
  subject: string,
  body: string,
  toEmailAddresses: string[],
  contentType: 'Text' | 'HTML' = 'Text'
): Promise<Response> {
  if (toEmailAddresses.length === 0) {
    throw new Error(
      'Attempting to send email without any recipient email addresses'
    );
  }
  if (!authInfo) {
    throw new Error('Attempting to send email without any authentication info');
  }

  let accessToken: string;
  if (typeof authInfo === 'string') {
    accessToken = authInfo;
  } else {
    const response = await acquireMsalToken(pca, authInfo);
    accessToken = response.accessToken;
  }

  const email = {
    message: {
      subject: subject,
      body: {
        contentType: contentType,
        content: body,
      },
      toRecipients: toEmailAddresses.map((address) => ({
        emailAddress: { address: address.trim() },
      })),
    },
  };

  return await axios.post(
    'https://graph.microsoft.com/v1.0/me/sendMail',
    email,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
}

/**
 * Attempts to silently sign in the user using MSAL.
 *
 * @param msalApp The MSAL app instance.
 * @param pcaConfig The MSAL configuration.
 * @param tenantId The tenant ID.
 * @param uid The user ID.
 * @param email The user email.
 *
 * @warning This function catches all errors thrown by msal. Instances of
 * `InteractionRequiredAuthError` are discarded silently. Other errors are logged.
 */
export async function attemptSsoSilentMsalLogin(
  msalApp: PublicClientApplication,
  tenantId: string,
  uid: string,
  email: string
) {
  try {
    await msalApp.ssoSilent({
      ...loginRequest,
      loginHint: email,
    });
    const config = msalApp.getConfiguration();
    logEvent(analytics, 'msal_sso_silent_success', {
      tenant_id: tenantId,
      user_id: uid,
      user_email: email,
      client_id: config.auth.clientId,
      authority: config.auth.authority,
      redirect_uri: config.auth.redirectUri,
    });
    console.log(`MSAL silent SSO succeeded for user ${email}`);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // The user needs to sign in interactively, but this function is not called
      // in an interactive context. Do nothing.
      return;
    } else {
      captureException(error);
      console.error(
        `MSAL silent SSO failed with error '${
          (error as Error).message
        }' for user ${email}`
      );
    }
  }
}
