//src/components/LandingPage/HeaderBar.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/full-white-logo.webp';
import './LandingPage.css';

const HeaderBar = ({ toggleModal }) => {

  const history = useHistory();
  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="header-bar">
      <img src={logo} alt="Practist Logo" className="landing-logo"/>
      <div className="header-buttons">
        <button onClick={() => navigateTo('/login')} className="outline-button" style={{ cursor: 'pointer', position: 'relative', zIndex: 1000 }}>Sign In</button>
        <button onClick={toggleModal} className="button">Join Waitlist</button>
      </div>
    </div>
  );
};

export default HeaderBar;