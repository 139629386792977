import {
  HumanDocument,
  OrganizationDocument,
  UserDocument,
  InsuranceClaim,
  MatterData,
} from './baseTypes';

import firebase from 'firebase/compat/app';
import DocumentReference = firebase.firestore.DocumentReference;

export class FlattenedMatter {
  // Required fields
  contacts: { humans: HumanDocument[]; organizations: OrganizationDocument[] };
  description: string;
  id: string;
  matterRef: DocumentReference<MatterData>;
  matterType: string;
  name: string;
  state: string;
  status: string;

  // Optional fields
  adminFee?: number;
  adverseParty?: string;
  amtOwed?: string;
  caseAssist?: string;
  caseNumber?: string;
  caseValue?: string;
  costRetainer?: number;
  createdTimestamp?: Date;
  engagementOriginator?: UserDocument;
  flatFee?: number;
  flatFeeAmt?: string;
  insuranceCaseType?: string;
  leadSource?: string;
  memberId?: string;
  metLifeClaimNumber?: string;
  #primaryContact?: HumanDocument;
  referralOriginator?: UserDocument;
  responsibleAttorney?: UserDocument;
  responsibleAttyHourlyRate?: string;
  retainer?: string;
  scope?: string;
  signedEngagementLetters?: URL[];
  socialSecurityNumber?: string;
  totalAmountBilled?: string;
  totalAmountPaid?: number;
  trustBalance?: number;
  workInProgress?: string;
  claims?: InsuranceClaim[];

  constructor(dict: {
    // Required fields
    contacts: {
      humans: HumanDocument[];
      organizations: OrganizationDocument[];
    };
    description: string;
    id: string;
    matterRef: DocumentReference<MatterData>;
    matterType: string;
    name: string;
    state: string;
    status: string;

    // Optional fields
    adminFee?: number;
    adverseParty?: string;
    amtOwed?: string;
    caseAssist?: string;
    caseNumber?: string;
    caseValue?: string;
    costRetainer?: number;
    createdTimestamp?: Date;
    engagementOriginator?: UserDocument;
    flatFee?: number;
    flatFeeAmt?: string;
    insuranceCaseType?: string;
    leadSource?: string;
    memberId?: string;
    metLifeClaimNumber?: string;
    primaryContact?: HumanDocument;
    referralOriginator?: UserDocument;
    responsibleAttorney?: UserDocument;
    responsibleAttyHourlyRate?: string;
    retainer?: string;
    scope?: string;
    signedEngagementLetters?: URL[];
    socialSecurityNumber?: string;
    totalAmountBilled?: string;
    totalAmountPaid?: number;
    trustBalance?: number;
    workInProgress?: string;
    claims?: InsuranceClaim[];
  }) {
    // Required fields
    this.contacts = dict.contacts;
    this.description = dict.description;
    this.id = dict.id;
    this.matterRef = dict.matterRef;
    this.matterType = dict.matterType;
    this.name = dict.name;
    this.state = dict.state;
    this.status = dict.status;

    // Optional properties
    this.adminFee = dict.adminFee;
    this.adverseParty = dict.adverseParty;
    this.amtOwed = dict.amtOwed;
    this.caseAssist = dict.caseAssist;
    this.caseNumber = dict.caseNumber;
    this.caseValue = dict.caseValue;
    this.costRetainer = dict.costRetainer;
    this.createdTimestamp = dict.createdTimestamp;
    this.engagementOriginator = dict.engagementOriginator;
    this.flatFee = dict.flatFee;
    this.flatFeeAmt = dict.flatFeeAmt;
    this.insuranceCaseType = dict.insuranceCaseType;
    this.leadSource = dict.leadSource;
    this.memberId = dict.memberId;
    this.metLifeClaimNumber = dict.metLifeClaimNumber;
    this.#primaryContact = dict.primaryContact;
    this.referralOriginator = dict.referralOriginator;
    this.responsibleAttorney = dict.responsibleAttorney;
    this.responsibleAttyHourlyRate = dict.responsibleAttyHourlyRate;
    this.retainer = dict.retainer;
    this.scope = dict.scope;
    this.signedEngagementLetters = dict.signedEngagementLetters;
    this.socialSecurityNumber = dict.socialSecurityNumber;
    this.totalAmountBilled = dict.totalAmountBilled;
    this.totalAmountPaid = dict.totalAmountPaid;
    this.trustBalance = dict.trustBalance;
    this.workInProgress = dict.workInProgress;
  }

  get humans(): HumanDocument[] {
    return this.contacts.humans;
  }

  get organizations(): OrganizationDocument[] {
    return this.contacts.organizations;
  }

  get primaryContact(): HumanDocument | undefined {
    if (this.#primaryContact) {
      return this.#primaryContact;
    }
    if (this.contacts.humans.length > 0) {
      return this.contacts.humans[0];
    }
    return undefined;
  }

  get otherContacts(): HumanDocument[] {
    if (this.contacts.humans.length > 1) {
      const remainingContacts = this.contacts.humans
        .filter((contact) => {
          return contact.id !== this.primaryContact?.id;
        })
        .sort((a, b) => {
          return (a ?? '').lastName.localeCompare((b ?? '').lastName);
        });
      return remainingContacts;
    }
    return [];
  }

  get companyName(): string | undefined {
    if (this.contacts.organizations.length > 0) {
      return this.contacts.organizations[0].companyName;
    }
    return undefined;
  }
}
