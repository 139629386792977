import heroImage from '../../assets/landing-page1.webp';
import './LandingPage.css';
import { useHistory } from 'react-router-dom';

const HeroSection = ({ toggleModal }) => {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <div className="hero-section">
      <div className="text-container">
        {/* <a
          href="https://youtu.be/dQw4w9WgXcQ?si=Prjm02Pw2HWSd3bf"
          className="investor-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          For investors: view slide deck
          <ChevronRightIcon />
        </a> */}
        <h1>Built for<br />Access to<br />Justice.</h1>
        <p>Efficiency means lower overhead. Ease of use means no time wasted. Practist makes happier, more accessible, more profitable law firms.</p>
        <div className="buttons-container">
          <button onClick={() => navigateTo('/login')} className="outline-button" style={{ cursor: 'pointer' }}>Sign In</button>
          <button onClick={toggleModal} className="button">Join Waitlist</button>
        </div>
      </div>
      <img src={heroImage} alt="Hero" className="hero-image" />
    </div>
  );
};

export default HeroSection;

