import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig = (location: Location): Configuration => {
  return {
    auth: practistAuth(location),
    cache: {
      // Using localStorage cache so that the user remains logged in between browser sessions.
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  };
};

export const loginRequest = {
  scopes: ['user.read', 'mail.send'],
};

const practistAuth = (location: Location) => {
  return {
    clientId: '76b08512-fb20-4c02-b8b0-d8816a2834a5',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${location.origin}/blank.html`,
  };
};
