import {
  ContactWithRoleData,
  HumanData,
  MatterData,
} from '../../database/baseTypes';
import { FirmDatabase } from '../../database/firmDatabase';
import { AddEditMatterFormData } from './types';
import { FlattenedMatter } from '../../database/aggregateTypes';
import firebase from 'firebase/compat/app';
import DocumentReference = firebase.firestore.DocumentReference;

export const assertUnreachable = (x: never): never => {
  throw new Error("Didn't expect to get here");
};

export const saveFormData = async (
  db: FirmDatabase,
  matter: FlattenedMatter | undefined,
  formData: AddEditMatterFormData
) => {
  if (matter) {
    await updateExistingMatter(db, matter, formData);
  } else {
    await createNewMatter(db, formData);
  }
  return;
};

const createNewMatter = async (
  db: FirmDatabase,
  formData: AddEditMatterFormData
) => {
  if (!formData.state) {
    throw new Error('State is required');
  }

  if (!formData.matterStatus) {
    throw new Error('Matter status is required');
  }

  if (!formData.matterType) {
    throw new Error('Matter type is required');
  }

  // Create the raw matter data
  const matterData: MatterData = {
    createdTimestamp: new Date(),
    matterType: formData.matterType ?? `Don't Know`,
    name: constructMatterNameFromFormData(formData),
    responsibleAttorney: formData.responsibleAttorneyId ?? undefined,
    state: formData.state,
    status: formData.matterStatus,
    adverseParty: formData.adverseParty ?? undefined,
    description: formData.description ?? undefined,
    leadSource: formData.leadSource ?? undefined,
    scope: formData.scope ?? undefined,
  };

  switch (formData.matterType) {
    case 'ARAG':
      matterData.caseAssist = formData.caseAssist ?? undefined;
      matterData.memberId = formData.memberId ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'MetLife':
      matterData.metLifeClaimNumber = formData.metLifeClaimNumber ?? undefined;
      matterData.socialSecurityNumber =
        formData.socialSecurityNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'LegalEase':
      matterData.caseNumber = formData.caseNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'Hourly':
      matterData.retainer = formData.retainer ?? undefined;
      matterData.responsibleAttyHourlyRate =
        formData.responsibleAttyHourlyRate ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case 'FlatFee':
      matterData.flatFeeAmt = formData.flatFeeAmt ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case `Don't Know`:
      break;
    default:
      return assertUnreachable(formData);
      break;
  }

  const matterRef = await db.addMatter(matterData);
  await addOrUpdateMatterContacts(db, matterRef, formData);
};

const deleteRemovedContacts = async (
  matterRef: DocumentReference<MatterData>,
  formData: AddEditMatterFormData
) => {
  const existingClientSnapshots = await matterRef
    .collection('contactsWithRoles')
    .where('role', '==', 'client')
    .get();
  for (const existingClientSnapshot of existingClientSnapshots.docs) {
    const existingClientData = existingClientSnapshot.data();
    if (
      !formData.humanContacts.some(
        (humanContact) =>
          humanContact.firestoreId === existingClientData.contact.id
      ) &&
      (!formData.orgContact ||
        formData.orgContact.firestoreId !== existingClientData.contact.id)
    ) {
      await existingClientSnapshot.ref.delete();
    }
  }
};

const addOrUpdateOrgContact = async (
  db: FirmDatabase,
  matterRef: DocumentReference<MatterData>,
  formData: AddEditMatterFormData,
  representatives: DocumentReference<HumanData>[]
) => {
  if (formData.orgContact?.companyName) {
    if (formData.orgContact.firestoreId) {
      // Update the existing document in the `organizations` collection
      const orgSnapshot = await db.getOrganization(
        formData.orgContact.firestoreId
      );
      if (!orgSnapshot.exists) {
        throw new Error(
          `Organization with id ${formData.orgContact.firestoreId} not found`
        );
      }
      await orgSnapshot.ref.update({
        companyName: formData.orgContact.companyName,
        representatives,
      });

      // Add the organization to the matter if needed
      const existingClientSnapshot = await matterRef
        .collection('contactsWithRoles')
        .where('contact', '==', orgSnapshot.ref)
        .where('role', '==', 'client')
        .get();
      if (existingClientSnapshot.empty) {
        await matterRef.collection('contactsWithRoles').add({
          role: 'client',
          contact: orgSnapshot.ref,
        });
      }
    } else {
      // Add a new document to the `organizations` collection
      const orgRef = await db.addOrganization({
        companyName: formData.orgContact.companyName,
        representatives,
      });

      // Add the organization as a client
      await matterRef.collection('contactsWithRoles').add({
        role: 'client',
        contact: orgRef,
      });
    }
  }
};

const addOrUpdateHumanContacts = async (
  db: FirmDatabase,
  matterRef: DocumentReference<MatterData>,
  formData: AddEditMatterFormData
) => {
  const humanRefs: DocumentReference<HumanData>[] = [];
  let primaryContactRef: DocumentReference<ContactWithRoleData> | null = null;
  for (const humanContact of formData.humanContacts) {
    if (humanContact.firestoreId) {
      // Update the existing document in the `humans` collection
      const humanSnapshot = await db.getHuman(humanContact.firestoreId);
      if (!humanSnapshot.exists) {
        throw new Error(`Human with id ${humanContact.firestoreId} not found`);
      }
      await humanSnapshot.ref.update({
        firstName: humanContact.firstName,
        lastName: humanContact.lastName,
        email: humanContact.email ?? undefined,
        phone: humanContact.phone ?? undefined,
      });

      // Add the human as a client if they aren't already
      const existingClientSnapshot = await matterRef
        .collection('contactsWithRoles')
        .where('contact', '==', humanSnapshot.ref)
        .where('role', '==', 'client')
        .get();
      if (existingClientSnapshot.empty) {
        const ref = (await matterRef.collection('contactsWithRoles').add({
          role: 'client',
          contact: humanSnapshot.ref,
        })) as DocumentReference<ContactWithRoleData>;
        if (humanContact.tempId === formData.primaryContactTempId) {
          primaryContactRef = ref;
        }
      } else {
        if (humanContact.tempId === formData.primaryContactTempId) {
          primaryContactRef = existingClientSnapshot.docs[0]
            .ref as DocumentReference<ContactWithRoleData>;
        }
      }
      humanRefs.push(humanSnapshot.ref);
    } else {
      // Add a new document to the `humans` collection
      const humanRef = await db.addHuman({
        firstName: humanContact.firstName,
        lastName: humanContact.lastName,
        email: humanContact.email ?? undefined,
        phone: humanContact.phone ?? undefined,
      });
      humanRefs.push(humanRef);

      // Add the human as a client
      const contactRef = await matterRef.collection('contactsWithRoles').add({
        role: 'client',
        contact: humanRef,
      });
      if (humanContact.tempId === formData.primaryContactTempId) {
        primaryContactRef =
          contactRef as DocumentReference<ContactWithRoleData>;
      }
    }
  }
  if (primaryContactRef) {
    await matterRef.update({ primaryContact: primaryContactRef });
  }

  return humanRefs;
};

const addOrUpdateMatterContacts = async (
  db: FirmDatabase,
  matterRef: DocumentReference<MatterData>,
  formData: AddEditMatterFormData
) => {
  await deleteRemovedContacts(matterRef, formData);
  const humanRefs = await addOrUpdateHumanContacts(db, matterRef, formData);
  await addOrUpdateOrgContact(db, matterRef, formData, humanRefs);
};

const updateExistingMatter = async (
  db: FirmDatabase,
  matter: FlattenedMatter,
  formData: AddEditMatterFormData
) => {
  if (!formData.state) {
    throw new Error('State is required');
  }

  if (!formData.matterStatus) {
    throw new Error('Matter status is required');
  }

  if (!formData.matterType) {
    throw new Error('Matter type is required');
  }

  await addOrUpdateMatterContacts(db, matter.matterRef, formData);

  // Create the raw matter data
  const matterData: Partial<MatterData> = {
    matterType: formData.matterType ?? `Don't Know`,
    responsibleAttorney: formData.responsibleAttorneyId || undefined,
    state: formData.state,
    status: formData.matterStatus,
  };

  if (formData.adverseParty) {
    matterData.adverseParty = formData.adverseParty;
  }

  if (formData.description) {
    matterData.description = formData.description;
  }

  if (formData.leadSource) {
    matterData.leadSource = formData.leadSource;
  }

  if (formData.responsibleAttorneyId) {
    matterData.responsibleAttorney = formData.responsibleAttorneyId;
  }

  if (formData.scope) {
    matterData.scope = formData.scope;
  }

  switch (formData.matterType) {
    case 'ARAG':
      matterData.caseAssist = formData.caseAssist ?? undefined;
      matterData.memberId = formData.memberId ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'MetLife':
      matterData.metLifeClaimNumber = formData.metLifeClaimNumber ?? undefined;
      matterData.socialSecurityNumber =
        formData.socialSecurityNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'LegalEase':
      matterData.caseNumber = formData.caseNumber ?? undefined;
      matterData.caseValue = formData.caseValue ?? undefined;
      matterData.insuranceCaseType = formData.insuranceCaseType ?? undefined;
      break;
    case 'Hourly':
      matterData.retainer = formData.retainer ?? undefined;
      matterData.responsibleAttyHourlyRate =
        formData.responsibleAttyHourlyRate ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case 'FlatFee':
      matterData.flatFeeAmt = formData.flatFeeAmt ?? undefined;
      matterData.referralOriginator =
        formData.referralOriginatorId ?? undefined;
      matterData.engagementOriginator =
        formData.engagementOriginatorId ?? undefined;
      break;
    case `Don't Know`:
      break;
    default:
      return assertUnreachable(formData);
      break;
  }
  await db.updateMatter(matter.id, matterData);
};

function constructMatterNameFromFormData(formData: AddEditMatterFormData) {
  if (formData.orgContact) {
    return formData.orgContact.companyName;
  }

  if (formData.humanContacts.length > 0) {
    // For multiple human contacts, join their names in the desired format
    // Assuming each contact in humanContacts array has lastName and firstName
    return formData.humanContacts
      .map((contact) => `${contact.lastName}, ${contact.firstName}`)
      .join('; ');
  }

  return 'Error: Missing Matter Name';
}
