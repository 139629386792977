import { useState, useEffect, useRef, useContext } from 'react';
import './AddEditMatterForm.css';
import {
  matterState,
  matterStatus,
  leadSource,
  matterTypeSelectOptions,
  MatterType,
} from '../../optionSets';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { FirmDatabase } from '../../database/firmDatabase';
import { SelectOption } from '../types';
import { AuthenticatedSessionContext } from '../../authenticatedSession/AuthenticatedSessionContext';
import { AddEditMatterFormData, FormType, OrganizationContact } from './types';
import ConflictsSearchBox from './subcomponents/ConflictsSearchBox';
import MatterTypeFields from './subcomponents/MatterTypeFields';
import { useUserSelectOptions } from '../hooks';
import { FlattenedMatter } from '../../database/aggregateTypes';
import { assertUnreachable, saveFormData } from './util';
import SelectField from './subcomponents/SelectField';
import MatterContactForm from './subcomponents/MatterContactForm';
import { v4 as uuidv4 } from 'uuid';
import { UseFormReturnType, useForm as useMantineForm } from '@mantine/form';
import {
  Alert,
  Button,
  LoadingOverlay,
  Overlay,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';
import { captureException } from '@sentry/react';
import { LoadingMessage } from '../LoadingMessage';

interface Props {
  matter?: FlattenedMatter;
  formType: FormType;
  onBack: () => void;
  onClose: () => void;
}

export type MantineForm = UseFormReturnType<
  AddEditMatterFormData,
  (values: AddEditMatterFormData) => AddEditMatterFormData
>;

type SaveStatus = { status: 'loading' } | { status: 'error'; message: string };

const AddEditMatterForm = ({ matter, formType, onBack, onClose }: Props) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const userSelectOptions = useUserSelectOptions();
  const [selectedConflict, setSelectedConflict] = useState<SelectOption | null>(
    null
  );
  const [saveStatus, setSaveStatus] = useState<SaveStatus>();
  const [modalShake, setModalShake] = useState(false);

  const defaultOrgContact: OrganizationContact | null = matter?.contacts
    .organizations[0]
    ? {
        companyName: matter.contacts.organizations[0].companyName,
        firestoreId: matter.contacts.organizations[0].id,
      }
    : formType === 'company'
    ? { companyName: '', firestoreId: null }
    : null;
  const defaultTempId = 'default_temp_id';

  const mantineForm: MantineForm = useMantineForm<AddEditMatterFormData>({
    mode: 'uncontrolled',
    initialValues: {
      humanContacts: matter?.contacts.humans
        ? matter.contacts.humans.map((human) => {
            return {
              tempId: human.id,
              firestoreId: human.id,
              firstName: human.firstName,
              lastName: human.lastName,
              phone: human.phone ?? null,
              email: human.email ?? null,
            };
          })
        : [
            {
              tempId: defaultTempId,
              firestoreId: null,
              firstName: '',
              lastName: '',
              phone: null,
              email: null,
            },
          ],
      primaryContactTempId: matter?.primaryContact?.id ?? defaultTempId,
      orgContact: defaultOrgContact,
      state: matter?.state ?? null,
      matterStatus: matter?.status ?? 'new',
      responsibleAttorneyId: matter?.responsibleAttorney?.id ?? null,
      leadSource: matter?.leadSource ?? null,
      description: matter?.description ?? null,
      scope: matter?.scope ?? null,
      conflict: null,
      adverseParty: matter?.adverseParty ?? null,
      matterType: (matter?.matterType as MatterType) ?? null,
      caseAssist: matter?.caseAssist ?? null,
      memberId: matter?.memberId ?? null,
      caseValue: matter?.caseValue ?? null,
      insuranceCaseType: matter?.insuranceCaseType ?? null,
      metLifeClaimNumber: matter?.metLifeClaimNumber ?? null,
      socialSecurityNumber: matter?.socialSecurityNumber ?? null,
      caseNumber: matter?.caseNumber ?? null,
      retainer: matter?.retainer ?? null,
      responsibleAttyHourlyRate: matter?.responsibleAttyHourlyRate ?? null,
      referralOriginatorId: matter?.referralOriginator?.id ?? null,
      engagementOriginatorId: matter?.engagementOriginator?.id ?? null,
      flatFeeAmt: matter?.flatFeeAmt ?? null,
    },
  });

  const isCompany =
    matter?.contacts.organizations[0] !== undefined || formType === 'company';

  // These are arrays of types of matters for person and company. They are hardcoded and don't change.
  const matterTypes = matterTypeSelectOptions.map((option) => option.value);

  useEffect(() => {
    if (modalShake) {
      const timer = setTimeout(() => {
        setModalShake(false);
      }, 500); // The duration of the shake animation

      return () => clearTimeout(timer);
    }
  }, [modalShake]);

  // This function is called when a matter type button is clicked.
  // It sets the selected matter type to the clicked type.
  const handleMatterTypeClick = (type: MatterType) => {
    switch (type) {
      case 'ARAG':
        mantineForm.setValues({
          matterType: 'ARAG',
          caseAssist: '',
          memberId: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'MetLife':
        mantineForm.setValues({
          matterType: 'MetLife',
          metLifeClaimNumber: '',
          socialSecurityNumber: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'LegalEase':
        mantineForm.setValues({
          matterType: 'LegalEase',
          caseNumber: '',
          caseValue: '',
          insuranceCaseType: '',
        });
        break;
      case 'Hourly':
        mantineForm.setValues({
          matterType: 'Hourly',
          retainer: '',
          responsibleAttyHourlyRate: '',
          referralOriginatorId: null,
          engagementOriginatorId: null,
        });
        break;
      case 'FlatFee':
        mantineForm.setValues({
          matterType: 'FlatFee',
          flatFeeAmt: '',
          referralOriginatorId: null,
          engagementOriginatorId: null,
        });
        break;
      case `Don't Know`:
        mantineForm.setValues({
          matterType: `Don't Know`,
        });
        break;
      default:
        return assertUnreachable(type);
    }
  };

  if (userSelectOptions.length === 0) {
    // Wait until these load, else the attorney-related select fields will be empty.
    return <LoadingMessage />;
  }

  return (
    <form
      id='add-edit-matter-form'
      className={`${modalShake ? 'shake' : ''}`}
      onSubmit={mantineForm.onSubmit(async (data) => {
        await submitForm(
          authSession?.db,
          matter,
          data,
          selectedConflict ?? undefined,
          setSaveStatus,
          setModalShake,
          onClose
        );
      })}
    >
      <Title order={1}>
        {!!matter ? matter.name : isCompany ? 'New Company' : 'New Person'}
      </Title>

      <section className='form-body'>
        {isCompany && (
          <section title='Company' className='company'>
            <Title order={2}>Company</Title>
            <TextInput
              label='Company Name'
              key={mantineForm.key('orgContact.companyName')}
              {...mantineForm.getInputProps('orgContact.companyName')}
            />
          </section>
        )}

        <section title='Add/Edit Contacts' className='add-edit-contacts'>
          <Title order={2}>{isCompany ? 'Representatives' : 'Contacts'}</Title>
          <ul>
            {mantineForm.getValues().humanContacts.map((field, index) => (
              <li
                title={`Contact ${index + 1}`}
                key={field.tempId}
                style={{
                  border: '1px solid #ddd',
                  padding: '1rem 1rem 1rem 1rem',
                  borderRadius: '0.5rem',
                }}
              >
                <MatterContactForm
                  title={`Contact ${index + 1}`}
                  contactIndex={index}
                  isPrimary={
                    mantineForm.getValues().primaryContactTempId ===
                    field.tempId
                  }
                  setPrimary={(isPrimary: boolean) => {
                    if (isPrimary) {
                      mantineForm.setValues({
                        primaryContactTempId: field.tempId,
                      });
                    } else if (
                      mantineForm.getValues().primaryContactTempId ===
                      field.tempId
                    ) {
                      mantineForm.setValues({ primaryContactTempId: null });
                    }
                  }}
                  form={mantineForm}
                  canDelete={mantineForm.getValues().humanContacts.length > 1}
                  onDelete={() =>
                    mantineForm.removeListItem('humanContacts', index)
                  }
                />
              </li>
            ))}
          </ul>
          <div className='add-contact-link'>
            <Button
              style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none',
                margin: '0 0 0 1rem',
                padding: '0',
                color: '#ff00bf',
                fontFamily: 'inherit',
              }}
              onClick={() =>
                mantineForm.insertListItem('humanContacts', {
                  tempId: uuidv4(),
                  firestoreId: null,
                  firstName: '',
                  lastName: '',
                  phone: null,
                  email: null,
                })
              }
              type='button' // Prevents form submission
            >
              Add Another Client for This Matter
            </Button>
          </div>
        </section>

        <section title='Matter Details' className='matter-details'>
          <Title order={2}>Matter Details</Title>
          <div className='input-row'>
            <SelectField
              fieldName='state'
              label='State'
              options={matterState}
              form={mantineForm}
            />
            <SelectField
              fieldName='matterStatus'
              label='Status'
              options={matterStatus}
              form={mantineForm}
            />
          </div>
          <div className='input-row'>
            <SelectField
              fieldName='responsibleAttorneyId'
              label='Responsible Attorney'
              options={userSelectOptions}
              form={mantineForm}
            />
            <SelectField
              fieldName='leadSource'
              label='Lead Source'
              options={leadSource}
              form={mantineForm}
            />
          </div>
          <Textarea
            label='Description'
            key={mantineForm.key('description')}
            {...mantineForm.getInputProps('description')}
          />
          <div className='input-row'>
            <TextInput
              className='field'
              label='Scope (for engagement letter)'
              key={mantineForm.key('scope')}
              {...mantineForm.getInputProps('scope')}
            />
            <ConflictsSearchBox
              onChange={(val) => setSelectedConflict(val ?? null)}
            />
          </div>
          <div className='input-row'>
            <TextInput
              className='field'
              label='Adverse Party'
              key={mantineForm.key('adverseParty')}
              {...mantineForm.getInputProps('adverseParty')}
            />
          </div>
          <section className='matter-type'>
            <Title order={3}>Select Matter Type</Title>
            <div className='matter-type-options'>
              {matterTypes.map((type) => (
                <button
                  className={`matter-type-button ${
                    type === mantineForm.getValues().matterType
                      ? 'selected'
                      : ''
                  }`}
                  type='button'
                  key={type}
                  onClick={() => handleMatterTypeClick(type)}
                >
                  {type}
                </button>
              ))}
            </div>

            <MatterTypeFields
              matterType={mantineForm.getValues().matterType ?? undefined}
              attorneys={userSelectOptions}
              form={mantineForm}
            />
          </section>
        </section>

        {saveStatus?.status === 'loading' && <Overlay color='#fff' />}
      </section>

      <section className='form-footer'>
        {saveStatus?.status === 'error' && (
          <Alert color='red' icon={<IconExclamationCircle />}>
            {saveStatus.message}
          </Alert>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '30px',
          }}
        >
          <IconButton
            disabled={saveStatus?.status === 'loading'}
            className='back-icon'
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <button
            style={{ position: 'relative' }}
            type='submit'
            disabled={saveStatus?.status === 'loading'}
          >
            Submit
            <LoadingOverlay
              loaderProps={{ color: '#ff00bf', size: 'sm' }}
              overlayProps={{ backgroundOpacity: 0 }}
              visible={saveStatus?.status === 'loading'}
            />
          </button>
          <button
            type='button'
            disabled={saveStatus?.status === 'loading'}
            className='outline-button'
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </section>
    </form>
  );
};

// This is the function that is called when the form is submitted.
const submitForm = async (
  firmDb: FirmDatabase | undefined,
  matter: FlattenedMatter | undefined,
  data: AddEditMatterFormData,
  selectedConflict: SelectOption | undefined,
  onStatusChange: (status: SaveStatus | undefined) => void,
  onModalShake: (shake: boolean) => void,
  onClose: () => void
) => {
  if (!firmDb) {
    console.warn('Attempting to create a matter without a database');
    return;
  }

  if (selectedConflict) {
    onStatusChange({
      status: 'error',
      message: 'Cannot submit form if there is a name in the Conflicts input.',
    });
    onModalShake(true);
    return;
  }

  onStatusChange({ status: 'loading' });
  try {
    await saveFormData(firmDb, matter, data);
    // After the form is submitted, clear the conflicts error and close the popup.
    onStatusChange(undefined);
    onClose();
    const event = new CustomEvent('formSubmitted');
    window.dispatchEvent(event);
  } catch (e) {
    onStatusChange({
      status: 'error',
      message: `Save failed: ${(e as Error).message}`,
    });
    captureException(e);
  }
};

export default AddEditMatterForm;
