import { useContext, useEffect } from 'react';
import { AuthenticatedSessionContext } from './authenticatedSession/AuthenticatedSessionContext';
import { captureException } from './sentry/sentry';

const Callback = () => {
  const session = useContext(AuthenticatedSessionContext);
  useEffect(() => {
    if (!session) {
      return;
    }
    session.msalApp
      .handleRedirectPromise()
      .then((response) => {
        if (response !== null) {
          const account = response.account;
          localStorage.setItem('msalAccount', JSON.stringify(account)); // Save the account info
          console.log(
            'Authentication completed successfully. Account details:',
            account
          );
        } else {
          console.error('Authentication response was null');
        }
      })
      .catch((error) => {
        console.error('Error during login:', error);
        captureException(error);
      });
  }, [session]);

  return (
    <div>
      <p>
        If you see this message for more than three seconds, authentication has
        failed. Try clearing your brownser's cache and try again.
      </p>
    </div>
  );
};

export default Callback;

/* import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';  // Import useHistory hook

const Callback = () => {
  const firestore = firebase.firestore();
  const history = useHistory(); // Initialize useHistory hook

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('Received code in Callback:', code);

    if (code) {
      const uid = firebase.auth().currentUser.uid;
      const exchangeCode = firebase.functions().httpsCallable('exchangeCode');

      // Include the user's ID when calling the 'exchangeCode' function
      exchangeCode({ code, uid })
        .then((result) => {
          // The access token obtained from the 'exchangeCode' function
          const accessToken = result.data.accessToken;

          if (accessToken) {
            // Store the access token in Firestore
            firestore.collection('users').doc(uid).set({
              accessToken
            }, { merge: true });
          }

          // Redirect to the Dashboard component
          history.push('/dashboard');
        })
        .catch((error) => {
          console.error('Error exchanging code:', error);
        });
    }
  }, [history]);  // Add 'history' to the dependency array

  return <div>Processing...</div>;
};

export default Callback; */

/*import React, { useEffect } from 'react';

const Callback = () => {
  const firestore = firebase.firestore();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      const exchangeCode = firebase.functions().httpsCallable('exchangeCode');

      // The user's ID
      const userId = firebase.auth().currentUser.uid;

      exchangeCode({ code, uid: userId })
        .then((result) => {
          const { accessToken, refreshToken, expiresAt } = result.data;

          if (accessToken) {
            firestore.collection('users').doc(userId).set({
              accessToken,
              refreshToken,
              expiresAt,
            }, { merge: true });
          }
        })
        .catch((error) => {
          console.error('Error exchanging code:', error);
        });
    }
  }, []);

  return <div>Processing...</div>;
};


export default Callback; */
