import './ModalOptionPicker.css';
import { SelectOption } from '../types';

interface Props {
  options: SelectOption[];
  onOptionSelected: (option: SelectOption) => void;
  onClose: () => void;
}

const ModalOptionPicker = (props: Props) => {
  return (
    <div id='modal-option-picker' data-testid='form-type-selector'>
      {props.options.map((option) => (
        <button
          key={option.value}
          onClick={() => props.onOptionSelected(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default ModalOptionPicker;
