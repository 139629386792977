import { UseFormRegister } from 'react-hook-form';
import { SelectOption } from '../../types';
import { AddEditMatterFormData } from '../types';
import { NativeSelect, Select } from '@mantine/core';
import { MantineForm } from '../AddEditMatterForm';

const SelectField = ({
  fieldName,
  label,
  options,
  form,
}: {
  fieldName: keyof AddEditMatterFormData;
  label: string;
  options: SelectOption[];
  form: MantineForm;
}) => {
  return (
    <NativeSelect
      className='field'
      label={label}
      key={form.key(fieldName)}
      data={[{ label: '', value: '' }, ...options]}
      {...form.getInputProps(fieldName)}
    />
  );
};

export default SelectField;
