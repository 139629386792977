import './sentry/sentry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles.css';

// Catch errors related to deployment of lazily-loaded components.
// (https://github.com/vitejs/vite/issues/11804)
window.addEventListener('vite:preloadError', (event) => {
  console.log(
    `'vite:preloadError' event received, reloading page. Event:`,
    event
  );
  window.location.reload();
});

const root = createRoot(document.getElementById('root') as any);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
