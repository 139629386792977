import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthenticatedSessionContext } from '../authenticatedSession/AuthenticatedSessionContext';
import {
  ExpenseEntryData,
  ExpenseEntryDocument,
  FinancialInstitutionDocument,
  FirmDocument,
  HumanDocument,
  KnowledgeBasePageDocument,
  MatterData,
  MatterDocument,
  MatterDocumentDocument,
  NoteData,
  NoteDocument,
  OrganizationDocument,
  TaskData,
  TaskDocument,
  TimeEntryData,
  TimeEntryDocument,
  TransactionData,
  UserData,
  UserDocument,
} from './baseTypes';
import { OrderByClause, WhereClause } from './queryBuilder';

export function useExpenseEntries(
  matterId: string | undefined,
  orderBy?: OrderByClause<ExpenseEntryData>
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [entries, setEntries] = useState<ExpenseEntryDocument[]>();

  useEffect(() => {
    setEntries(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToExpenseEntriesChanges(
      matterId ? [['matter', '==', matterId]] : [],
      orderBy ? [orderBy] : [],
      (collectionSnapshot) => {
        const items = collectionSnapshot.docs.map((docSnapshot) => {
          return { ...docSnapshot.data(), id: docSnapshot.id };
        });
        setEntries(items);
      }
    );
  }, [matterId]);

  return entries;
}

export function useExpenseEntryAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: ExpenseEntryData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addExpenseEntry(data);
  }, []);
}

export function useFinancialInstitutions() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [institutions, setInstitutions] = useState<
    FinancialInstitutionDocument[] | undefined
  >();
  useEffect(() => {
    setInstitutions(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToFinancialInstitutionsChanges(
      (snapshot) => {
        setInstitutions(
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      },
      [],
      []
    );
  }, [authSession]);

  return institutions;
}

export function useKnowledgeBasePages() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [pages, setPages] = useState<KnowledgeBasePageDocument[] | undefined>();
  useEffect(() => {
    setPages(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToKnowledgeBasePagesChanges(
      (snapshot) => {
        setPages(
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        );
      },
      [],
      [['createdTimestamp', 'asc']]
    );
  }, [authSession]);

  return pages;
}

export function useMatters(options?: {
  where?: WhereClause<MatterData>[];
  orderBy?: OrderByClause<MatterData>[];
}) {
  const [matters, setMatters] = useState<MatterDocument[]>();
  const authSession = useContext(AuthenticatedSessionContext);
  useEffect(() => {
    setMatters(undefined);
    if (!authSession) {
      return;
    }
    const where = options?.where ? [...options.where] : [];
    const orderBy = options?.orderBy ? [...options.orderBy] : [];
    if (orderBy.length === 0) {
      orderBy.push(['name', 'asc']);
    }
    return authSession.db.subscribeToMattersChanges(
      (collectionSnapshot) => {
        setMatters(
          collectionSnapshot.docs.map((docSnapshot) => {
            const data = docSnapshot.data();
            return { ...data, id: docSnapshot.id };
          })
        );
      },
      where,
      orderBy
    );
  }, [options, options?.where, options?.orderBy]);

  return matters;
}

export function useMatter(id: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [matter, setMatter] = useState<MatterDocument | undefined>();
  useEffect(() => {
    const fetchMatter = async () => {
      if (!authSession) {
        return;
      }
      const snapshot = await authSession.db.getMatter(id);
      setMatter({ ...snapshot.data(), id: snapshot.id } as MatterDocument);
    };
    fetchMatter();
  }, [authSession, id]);
  return matter;
}

export function useMatterContacts(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [contacts, setContacts] = useState<{
    humans: HumanDocument[];
    organizations: OrganizationDocument[];
  }>();
  useEffect(() => {
    setContacts(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToGroupedMatterContactsChanges(
      matterId,
      (contacts) => {
        setContacts(contacts);
      }
    );
  }, [matterId, authSession]);
  return contacts;
}

export function useMatterNotes(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [notes, setNotes] = useState<NoteDocument[]>();
  useEffect(() => {
    setNotes(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterNotesChanges(
      matterId,
      [['createdTimestamp', 'desc']],
      (collectionSnapshot) => {
        const notes = collectionSnapshot.docs
          .map((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data) {
              return null;
            }
            return { ...data, id: docSnapshot.id };
          })
          .filter((note) => !!note) as NoteDocument[];

        setNotes(notes);
      }
    );
  }, [matterId]);
  return notes;
}

export function useMatterNoteAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return async (matterId: string, data: NoteData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addMatterNote(matterId, data);
  };
}

export function useMatterDocuments(matterId: string) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [items, setItems] = useState<MatterDocumentDocument[]>();
  useEffect(() => {
    setItems(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToMatterDocumentsChanges(
      matterId,
      [['createdTimestamp', 'desc']],
      (collectionSnapshot) => {
        const documents = collectionSnapshot.docs
          .map((docSnapshot) => {
            const data = docSnapshot.data();
            if (!data) {
              return null;
            }
            return { ...data, id: docSnapshot.id };
          })
          .filter((doc) => !!doc) as MatterDocumentDocument[];

        setItems(documents);
      }
    );
  }, [matterId]);
  return items;
}

export function useUsers(
  where: WhereClause<UserData>[] = [],
  orderBy: OrderByClause<UserData>[] = []
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [users, setUsers] = useState<UserDocument[]>();
  useEffect(() => {
    setUsers(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToUsersChanges(
      (collectionSnapshot) => {
        setUsers(
          collectionSnapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        );
      },
      where,
      orderBy
    );
  }, [JSON.stringify(where), JSON.stringify(orderBy), authSession]);
  return users;
}

export function useCurrentUser() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [currentUser, setCurrentUser] = useState<UserDocument | undefined>();
  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!authSession) {
        return;
      }
      const snapshot = await authSession.db.getUser(authSession.userId);
      setCurrentUser({ ...snapshot.data(), id: snapshot.id } as UserDocument);
    };
    fetchCurrentUser();
  }, [authSession]);

  return currentUser;
}

export function useFirm() {
  const authSession = useContext(AuthenticatedSessionContext);
  const [firm, setFirm] = useState<FirmDocument | undefined>();
  useEffect(() => {
    const fetchFirm = async () => {
      if (!authSession) {
        return;
      }
      const snapshot = await authSession.db.getFirm();
      setFirm({ ...snapshot.data(), id: snapshot.id } as FirmDocument);
    };
    fetchFirm();
  }, [authSession]);

  return firm;
}

export function useTasks(
  matterId: string | undefined,
  orderBy: OrderByClause<TaskData>[] = []
) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [tasks, setTasks] = useState<TaskDocument[]>();
  useEffect(() => {
    setTasks(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToTasksChanges(
      (collectionSnapshot) => {
        setTasks(
          collectionSnapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        );
      },
      matterId ? [['matter', '==', matterId]] : [],
      orderBy
    );
  }, [matterId, JSON.stringify(orderBy), authSession]);
  return tasks;
}

export function useTaskAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TaskData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTask(data);
  }, []);
}

export function useTimeEntries(matterId: string | undefined) {
  const authSession = useContext(AuthenticatedSessionContext);
  const [timeEntries, setTimeEntries] = useState<TimeEntryDocument[]>();
  useEffect(() => {
    setTimeEntries(undefined);
    if (!authSession) {
      return;
    }
    return authSession.db.subscribeToTimeEntriesChanges(
      matterId ? [['matter', '==', matterId]] : [],
      [],
      (collectionSnapshot) => {
        setTimeEntries(
          collectionSnapshot.docs
            .map((docSnapshot) => {
              return { ...docSnapshot.data(), id: docSnapshot.id };
            })
            .sort((a, b) => (a.earnedYmd.isOnOrAfter(b.earnedYmd) ? -1 : 1))
        );
      }
    );
  }, [matterId, authSession]);

  return timeEntries;
}

export function useTimeEntryAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TimeEntryData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTimeEntry(data);
  }, []);
}

export function useTransactionAdd() {
  const authSession = useContext(AuthenticatedSessionContext);
  return useCallback(async (data: TransactionData) => {
    if (!authSession) {
      return;
    }
    return await authSession.db.addTransaction(data);
  }, []);
}
