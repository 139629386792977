import { MantineForm } from '../AddEditMatterForm';
import { Checkbox, TextInput, Title } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { ActionIcon } from '@mantine/core';

const MatterContactForm = ({
  title,
  contactIndex,
  isPrimary,
  setPrimary,
  form,
  canDelete,
  onDelete,
}: {
  title: string;
  contactIndex: number;
  isPrimary: boolean;
  setPrimary: (isPrimary: boolean) => void;
  form: MantineForm;
  canDelete: boolean;
  onDelete: () => void;
}) => {
  return (
    <>
      <header
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          padding: '0',
          marginBottom: '1rem',
        }}
      >
        <Title order={3} style={{ margin: '0', color: '#333' }}>
          {title}
        </Title>
        {canDelete && (
          <ActionIcon
            variant='subtle'
            aria-label={`Delete ${title}`}
            onClick={onDelete}
          >
            <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
          </ActionIcon>
        )}
      </header>
      <div className='fields'>
        <div className='input-row'>
          <TextInput
            className='field'
            label='First Name'
            key={form.key(`humanContacts.${contactIndex}.firstName`)}
            {...form.getInputProps(`humanContacts.${contactIndex}.firstName`)}
          />
          <TextInput
            className='field'
            label='Last Name'
            key={form.key(`humanContacts.${contactIndex}.lastName`)}
            {...form.getInputProps(`humanContacts.${contactIndex}.lastName`)}
          />
        </div>
        <div className='input-row'>
          <TextInput
            className='field'
            label='Phone'
            key={form.key(`humanContacts.${contactIndex}.phone`)}
            {...form.getInputProps(`humanContacts.${contactIndex}.phone`)}
          />
          <TextInput
            className='field'
            label='Email'
            key={form.key(`humanContacts.${contactIndex}.email`)}
            {...form.getInputProps(`humanContacts.${contactIndex}.email`)}
          />
        </div>
        <div
          className='input-row'
          style={{
            display: 'flex',
            marginTop: '0.5rem',
          }}
        >
          <Checkbox
            color='#ff00bf'
            checked={isPrimary}
            onChange={(e) => setPrimary(!isPrimary)}
            label='Primary Contact'
          />
        </div>
      </div>
    </>
  );
};

export default MatterContactForm;
