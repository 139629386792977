import { useEffect, useState } from 'react';
import { useUsers } from '../database/hooks';
import { SelectOption } from './types';

export function useUserSelectOptions(): SelectOption<string>[] {
  const users = useUsers();
  const [userSelectOptions, setUserSelectOptions] = useState<
    SelectOption<string>[]
  >([]);
  useEffect(() => {
    if (!users) {
      setUserSelectOptions([]);
      return;
    }
    setUserSelectOptions(
      users
        .map((user) => {
          return {
            value: user.id,
            label: user.firstName + ' ' + user.lastName,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [users]);
  return userSelectOptions;
}
