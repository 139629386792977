import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDG_Xdlt6gyYdR_lH66_K8zaqrI_e574ko',
  authDomain: 'breakfast-8ac2d.firebaseapp.com',
  projectId: 'breakfast-8ac2d',
  storageBucket: 'breakfast-8ac2d.appspot.com',
  messagingSenderId: '423798668073',
  appId: '1:423798668073:web:816d57444ced42a2ae1538',
  measurementId: 'G-66059JGPQV',
};

const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true });
const auth = getAuth(app);
const functions = firebase.functions();
const storage = firebase.storage();
const analytics = getAnalytics(app);

if (
  process.env.NODE_ENV === 'development' &&
  import.meta.env.VITE_APP_USE_FIREBASE_EMULATORS == 'true'
) {
  const host = import.meta.env.VITE_APP_FIREBASE_EMULATOR_HOST ?? '127.0.0.1';
  const authPort =
    import.meta.env.VITE_APP_FIREBASE_AUTH_EMULATOR_PORT ?? '9099';
  const firestorePort =
    import.meta.env.VITE_APP_FIREBASE_FIRESTORE_EMULATOR_PORT ?? '8080';
  const storagePort =
    import.meta.env.VITE_APP_FIREBASE_STORAGE_EMULATOR_PORT ?? '9199';
  const functionsPort =
    import.meta.env.VITE_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT ?? '5001';
  console.info(`Practist is using Firebase emulators`);
  console.log(`Auth emulator: http://${host}:${authPort}`);
  console.log(`Firestore emulator: http://${host}:${firestorePort}`);
  console.log(`Storage emulator: http://${host}:${storagePort}`);
  console.log(`Functions emulator: http://${host}:${functionsPort}`);

  connectAuthEmulator(auth, `http://${host}:${authPort}`);
  db.useEmulator(host, parseInt(firestorePort, 10));
  storage.useEmulator(host, parseInt(storagePort, 10));
  functions.useEmulator(host, parseInt(functionsPort, 10));
}

export { db, auth, functions, firebase, storage, analytics };
