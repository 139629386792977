import { firebase } from '../../firebaseConfig';

interface ChatGPTMessage {
  role: string;
  content?: string;
}

export async function createMatterIfReady(
  conversation: ChatGPTMessage[]
): Promise<boolean> {
  const summaryMessage = conversation.find((msg) => {
    if (msg.role != 'assistant') {
      return false;
    }
    if (!msg.content) {
      return false;
    }
    const firstNameFound = msg.content.includes('-firstName');
    const lastNameFound = msg.content.includes('-lastName');
    return firstNameFound && lastNameFound;
  });

  if (!summaryMessage?.content) {
    return false;
  }

  const summaryLines = summaryMessage.content.split('\n');
  const matterData: any = {
    createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const humanData: any = {};
  const humanFields = ['firstName', 'lastName', 'email', 'phone'];
  const fieldPattern = /^-(\w+): (.+)$/;
  for (const line of summaryLines) {
    const match = line.match(fieldPattern);
    if (!match) {
      continue;
    }

    const [, key, value] = match;
    if (humanFields.includes(key)) {
      humanData[key] = value;
    } else {
      matterData[key] = value;
    }
  }

  const autoSendConfirmationEmail = firebase
    .functions()
    .httpsCallable('autoSendConfirmationEmail');
  await autoSendConfirmationEmail({
    origin: location.origin,
    matterData: matterData,
    humanData: humanData,
  });

  return true;
}

export async function pollForNewMessages(
  proxyUrl: string,
  threadId: string,
  currentConversation: any[],
  attemptCount: number = 0
): Promise<any> {
  if (attemptCount > 20) {
    throw new Error('Timeout while waiting for new messages');
  }
  console.log(`attemptCount:`, attemptCount);

  const response = await fetch(proxyUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      path: `/threads/${threadId}/messages`,
      method: 'GET',
      headers: {
        'OpenAI-Beta': 'assistants=v1',
      },
    }),
  });
  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  const responseBody = await response.json();
  const newMessages = responseBody.data.filter((msg: any) => {
    if (!msg.content[0]?.text?.value) {
      console.warn(`Empty message received: ${msg.id}`);
      return false;
    }
    return (
      currentConversation.find((currentMsg) => {
        return currentMsg.id == msg.id;
      }) == null
    );
  });

  if (newMessages.length > 0) {
    // New messages found. Our wait is over.
    return responseBody.data;
  }

  // Give the assistant some time.
  const delayMs = 1000;
  await new Promise((resolve) => setTimeout(resolve, delayMs));

  // Try again.
  return await pollForNewMessages(
    proxyUrl,
    threadId,
    currentConversation,
    attemptCount + 1
  );
}
