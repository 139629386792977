import AsyncSelect from 'react-select/async';
import { SelectOption } from '../../types';
import { useContext, useState, useEffect } from 'react';
import { AuthenticatedSessionContext } from '../../../authenticatedSession/AuthenticatedSessionContext';
import { FirmDatabase } from '../../../database/firmDatabase';
import { firebase } from '../../../firebaseConfig';
import { HumanData } from '../../../database/baseTypes';

const ConflictsSearchBox = ({
  onChange,
}: {
  onChange: (option: SelectOption | undefined) => void;
}) => {
  const authSession = useContext(AuthenticatedSessionContext);
  const firmDb = authSession?.db;
  const [allMatters, setAllMatters] = useState<SelectOption[]>([]);
  const [inputValue, setInputValue] = useState(''); // Track the input value

  // Styling for conflicts check
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      fontSize: '14px',
      border: state.isFocused ? '1px solid #FF00BF' : '1px solid #CCCCCC',
      borderRadius: '0.25rem',
      boxShadow: state.isFocused ? '0 0 8px rgba(255, 0, 191, 0.3)' : null,
      outline: 'none',
      '&:hover': {
        border: state.isFocused ? '1px solid #FF00BF' : '1px solid #CCCCCC',
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '1.25rem',
    }),
  };

  // Fetch all matters when the component mounts
  useEffect(() => {
    const fetchAllMatters = async () => {
      if (!firmDb) return;

      const mattersSnapshot = await firmDb.getMatters([]);
      const mattersList = mattersSnapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.data().name ?? '',
      }));
      setAllMatters(mattersList);
    };

    fetchAllMatters();
  }, [firmDb]);

  // Load options based on user input
  const loadOptions = (inputValue: string): Promise<SelectOption[]> => {
    return new Promise((resolve) => {
      const filteredOptions = allMatters.filter((matter) =>
        matter.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      resolve(filteredOptions);
    });
  };

  const handleChange = (option: SelectOption | null) => {
    if (option) {
      // If a conflict value is selected, throw an error or handle the conflict as needed
      alert(
        'Conflict detected! Please resolve the conflict before proceeding.'
      );
      onChange(undefined); // Clear the selection to prevent form submission with a conflict
      setInputValue(''); // Clear the input value
    } else {
      onChange(option ?? undefined);
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    return newValue; // Ensure the input value updates properly
  };

  return (
    <div className='field'>
      <label htmlFor='conflictsCheck'>Always search for conflicts!</label>
      <AsyncSelect
        aria-label='conflictsCheck'
        inputId='conflictsCheck'
        isClearable={false} // No "x" button, but users can delete text as normal
        styles={customStyles}
        cacheOptions
        loadOptions={loadOptions}
        onInputChange={handleInputChange} // Handle input change
        placeholder='Search here. Leave blank if none.'
        onChange={handleChange}
        noOptionsMessage={() => 'No results found'}
        inputValue={inputValue} // Control the input value
      />
    </div>
  );
};

export default ConflictsSearchBox;
