import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { logEvent } from '@firebase/analytics';
import { analytics } from '../firebaseConfig';

// Borrowed from https://stackoverflow.com/a/75911707/176304
const FirebaseAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: location.pathname,
      firebase_screen_class: 'not-tracked', // This should probably be the component name.
    });
  }, [location]);

  return null;
};

export default FirebaseAnalytics;
