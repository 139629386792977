import React, { useEffect } from 'react';
import AddEditMatterForm from './AddEditMatterForm';
import { FlattenedMatter } from '../../database/aggregateTypes';
import { FormType } from './types';
import ModalOptionPicker from './ModalOptionPicker';
import './AddEditMatterModal.css';

interface Props {
  matter?: FlattenedMatter;
  onClose: () => void;
}

const AddEditMatterModal: React.FC<Props> = (props) => {
  const [formType, setFormType] = React.useState<FormType | undefined>(
    props.matter
      ? props.matter.contacts.organizations.length > 0
        ? 'company'
        : 'person'
      : undefined
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const className = (event.target as Element)?.className ?? '';
      // Wrapping className in String initializer because we're seeing an object
      // instead of a string when the back button is clicked.
      if (String(className).startsWith('modal-backdrop') === true) {
        props.onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [props.onClose]);

  return (
    <div id='add-edit-matter-modal' className='modal-backdrop'>
      {formType ? (
        <AddEditMatterForm
          matter={props.matter}
          formType={formType}
          onBack={() => setFormType(undefined)}
          onClose={props.onClose}
        />
      ) : (
        <ModalOptionPicker
          options={[
            { label: 'New Person', value: 'person' },
            { label: 'New Company', value: 'company' },
          ]}
          onClose={props.onClose}
          onOptionSelected={(option) => setFormType(option.value as FormType)}
        />
      )}
    </div>
  );
};

export default AddEditMatterModal;
