import { QueryBuilder, WhereClause, OrderByClause } from './queryBuilder';
import { db } from '../firebaseConfig';
import firebase from 'firebase/compat/app';
import Firestore = firebase.firestore.Firestore;
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import CollectionReference = firebase.firestore.CollectionReference;
import {
  ContactWithRoleData,
  EngagementLetterData,
  ExpenseEntryData,
  FinancialInstitutionData,
  FirmData,
  GeneratedInvoiceData,
  HumanData,
  HumanDocument,
  InvoiceData,
  KnowledgeBasePageData,
  MatterData,
  MatterDocumentData,
  NoteData,
  OrganizationData,
  OrganizationDocument,
  TaskData,
  TimeEntryData,
  TransactionData,
  UserData,
  PaySheetData,
} from './baseTypes';

import { FlattenedMatter } from './aggregateTypes';
import { captureException } from '../sentry/sentry';
import { cleanDataToFirestore } from './converters';

export class FirmDatabase {
  firmId: string;
  firestore: Firestore;
  query: QueryBuilder;

  constructor(firmId: string, firestore: Firestore = db) {
    this.firmId = firmId;
    this.firestore = firestore;
    this.query = new QueryBuilder(firmId, firestore);
  }

  // -------------------------------------------------------------------------
  // Convenience methods
  // -------------------------------------------------------------------------

  /**
   * @param {*} matterData The new matter's data.
   * @param {*} contactDataArray An array of the objects that should be added
   *   to the new matter's contactsWithRoles. Each object should have form
   *   `{ contact: DocumentReference, role: string }`.
   * @returns A `DocumentReference` object.
   */
  async addMatter(
    matterData: MatterData,
    contactDataArray: ContactWithRoleData[] = []
  ): Promise<DocumentReference<MatterData>> {
    const matterRef = this.query.matters().doc();
    const contactsRef = this.query.matterContacts(matterRef.id);
    const batch = this.firestore.batch();
    batch.set(matterRef, matterData);
    for (const contactData of contactDataArray) {
      batch.set(contactsRef.doc(), contactData);
    }
    await batch.commit();

    // Returning a DocumentReference to match behavior of other add... methods.
    return matterRef;
  }

  async getFlattenedMatter(matterId: string): Promise<FlattenedMatter | null> {
    const matterDoc = await this.getMatter(matterId);
    if (!matterDoc.exists) {
      console.error(`No matter found with id ${matterId}.`);
      captureException(
        new Error(`Attempt to get flattened matter that does not exist.`),
        (scope) => {
          scope.setExtra('matterId', matterId);
          return scope;
        }
      );
      return null;
    }
    const matterData: any = matterDoc.data();
    const promises: Promise<any>[] = [];
    promises.push(this.getGroupedMatterContacts(matterId));
    if (matterData.responsibleAttorney) {
      promises.push(this.getUser(matterData.responsibleAttorney));
    } else {
      promises.push(Promise.resolve(null));
    }
    if (matterData.referralOriginator) {
      promises.push(this.getUser(matterData.referralOriginator));
    } else {
      promises.push(Promise.resolve(null));
    }
    if (matterData.engagementOriginator) {
      promises.push(this.getUser(matterData.engagementOriginator));
    } else {
      promises.push(Promise.resolve(null));
    }
    const [
      groupedContacts,
      responsibleAttorney,
      referralOriginator,
      engagementOriginator,
    ] = await Promise.all(promises);

    let primaryContact: HumanDocument | undefined = undefined;
    if (matterDoc.data()?.primaryContact) {
      const contactWithRoles = await matterDoc.data()?.primaryContact?.get();
      const contactData = contactWithRoles?.data();
      const humanSnapshot = await contactData?.contact.get();
      const humanData = humanSnapshot?.data();
      if (humanSnapshot && humanData) {
        primaryContact = {
          ...(humanData as HumanData),
          id: humanSnapshot.id,
        };
      }
    }

    const makeUser = (snapshot: DocumentSnapshot | undefined) => {
      const id = snapshot?.id;
      const data = snapshot?.data();
      return id && data
        ? {
            id,
            ...data,
          }
        : undefined;
    };

    return new FlattenedMatter({
      ...matterData,
      id: matterDoc.id,
      matterRef: matterDoc.ref,
      contacts: groupedContacts,
      responsibleAttorney: makeUser(responsibleAttorney),
      referralOriginator: makeUser(referralOriginator),
      engagementOriginator: makeUser(engagementOriginator),
      primaryContact: primaryContact,
    });
  }

  async getGroupedMatterContacts(matterId: string): Promise<{
    humans: HumanDocument[];
    organizations: OrganizationDocument[];
  }> {
    const flattenedContacts = await this.#getFlattenedMatterContacts(matterId);

    const groupedContacts: {
      humans: HumanDocument[];
      organizations: OrganizationDocument[];
    } = {
      humans: [],
      organizations: [],
    };

    for (const contact of flattenedContacts) {
      if (contact.ref.parent.path.endsWith('humans')) {
        groupedContacts.humans.push({
          id: contact.id,
          ...contact.data(),
        } as HumanDocument);
      } else if (contact.ref.parent.path.endsWith('organizations')) {
        groupedContacts.organizations.push({
          id: contact.id,
          ...contact.data(),
        } as OrganizationDocument);
      } else {
        console.error('Unexpected contact type:', contact);
        captureException('Unexpected contact type', {
          extra: {
            contactParentPath: contact.ref.parent.path,
            matterId: matterId,
            contactId: contact.id,
          },
        });
      }
    }
    groupedContacts.humans.sort((a, b) => {
      if (a.phone === b.phone) {
        return (a.lastName || '').localeCompare(b.lastName || '');
      }
      return (a.phone || '').localeCompare(b.phone || '');
    });

    return groupedContacts;
  }

  subscribeToGroupedMatterContactsChanges(
    matterId: string,
    observer: (snapshot: {
      humans: HumanDocument[];
      organizations: OrganizationDocument[];
    }) => void
  ): () => void {
    return this.#subscribeToFlattenedMatterContactsChanges(
      matterId,
      (docSnapshots) => {
        const groupedContacts: {
          humans: HumanDocument[];
          organizations: OrganizationDocument[];
        } = {
          humans: [],
          organizations: [],
        };

        for (const contact of docSnapshots) {
          if (contact.ref.parent.path.endsWith('humans')) {
            groupedContacts.humans.push({
              id: contact.id,
              ...contact.data(),
            } as HumanDocument);
          } else if (contact.ref.parent.path.endsWith('organizations')) {
            groupedContacts.organizations.push({
              id: contact.id,
              ...contact.data(),
            } as OrganizationDocument);
          } else {
            console.error('Unexpected contact type:', contact);
            captureException('Unexpected contact type', {
              extra: {
                contactParentPath: contact.ref.parent.path,
                matterId: matterId,
                contactId: contact.id,
              },
            });
          }
        }
        groupedContacts.humans.sort((a, b) => {
          if (a.phone === b.phone) {
            return (a.lastName || '').localeCompare(b.lastName || '');
          }
          return (a.phone || '').localeCompare(b.phone || '');
        });

        observer(groupedContacts);
      }
    );
  }

  async #getFlattenedMatterContacts(
    matterId: string
  ): Promise<DocumentSnapshot<HumanData | OrganizationData>[]> {
    const unflattenedContacts = await this.getMatterContacts(matterId);
    const result = (
      await Promise.all(
        unflattenedContacts.docs.map(async (doc) => {
          const contactRef = doc.data().contact;
          const result = await contactRef.get();
          return result;
        })
      )
    ).flat();
    return result;
  }

  #subscribeToFlattenedMatterContactsChanges(
    matterId: string,
    observer: (
      snapshot: DocumentSnapshot<HumanData | OrganizationData>[]
    ) => void
  ): () => void {
    return this.subscribeToMatterContactsChanges(
      matterId,
      async (collectionSnapshot) => {
        const result = (
          await Promise.all(
            collectionSnapshot.docs.map(async (doc) => {
              const contactRef = doc.data().contact;
              const result = await contactRef.get();
              return result;
            })
          )
        ).flat();
        observer(result);
      }
    );
  }

  // -------------------------------------------------------------------------
  // Base methods
  // -------------------------------------------------------------------------

  // engagementLetter methods

  /**
   * @returns A `DocumentSnapshot` object.
   */
  async getEngagementLetter(
    id: string
  ): Promise<DocumentSnapshot<EngagementLetterData>> {
    return await this.query.engagementLetter(id).get();
  }

  async setEngagementLetter(id: string, data: EngagementLetterData) {
    await this.query.engagementLetter(id).set(data);
  }

  // expenseEntries methods

  async getExpenseEntries(
    where: WhereClause<ExpenseEntryData>[] = []
  ): Promise<QuerySnapshot<ExpenseEntryData>> {
    return await this.query.expenseEntries(where, []).get();
  }

  async addExpenseEntry(
    data: ExpenseEntryData
  ): Promise<DocumentReference<ExpenseEntryData>> {
    return await this.query.expenseEntries().add(data);
  }

  async updateExpenseEntry(
    id: string,
    data: Partial<ExpenseEntryData>
  ): Promise<void> {
    this.#updateDocument(await this.query.expenseEntry(id), data);
  }

  async deleteExpenseEntry(id: string) {
    await this.query.expenseEntry(id).delete();
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @param {*} orderBy An array of [fieldPath, directionStr] tuples.
   * @param {*} observer The function called when relevant timeEntries change.
   * @returns The 'unsubscribe' function.
   */
  subscribeToExpenseEntriesChanges(
    where: WhereClause<ExpenseEntryData>[],
    orderBy: OrderByClause<ExpenseEntryData>[],
    observer: (snapshot: QuerySnapshot<ExpenseEntryData>) => void
  ): () => void {
    const unsubscribe = this.query
      .expenseEntries(where, orderBy)
      .onSnapshot(observer);
    const subscriptionName = 'expenseEntries';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // -------------------------------------------------------------------------
  // financialInstitutions

  async getFinancialInstitution(
    id: string
  ): Promise<DocumentSnapshot<FinancialInstitutionData>> {
    return await this.query.financialInstitution(id).get();
  }

  async addFinancialInstitution(
    data: FinancialInstitutionData
  ): Promise<DocumentReference<FinancialInstitutionData>> {
    return await this.query.financialInstitutions().add(data);
  }

  async updateFinancialInstitution(
    id: string,
    data: Partial<FinancialInstitutionData>
  ) {
    await this.#updateDocument(this.query.financialInstitution(id), data);
  }

  async deleteFinancialInstitution(id: string) {
    await this.query.financialInstitution(id).delete();
  }

  subscribeToFinancialInstitutionsChanges(
    observer: (snapshot: QuerySnapshot<FinancialInstitutionData>) => void,
    where?: WhereClause<FinancialInstitutionData>[],
    orderBy?: OrderByClause<FinancialInstitutionData>[]
  ): () => void {
    const unsubscribe = this.query
      .financialInstitutions(where, orderBy)
      .onSnapshot(observer);

    const subscriptionName = 'financialInstitutions';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // -------------------------------------------------------------------------
  // firms

  async getFirm(): Promise<DocumentSnapshot<FirmData>> {
    return await this.query.firm().get();
  }

  async updateFirm(data: Partial<FirmData>) {
    await this.#updateDocument(this.query.firm(), data);
  }

  // -------------------------------------------------------------------------
  // generatedInvoices

  /**
   * @returns A `DocumentSnapshot` object.
   */
  async getGeneratedInvoice(
    id: string
  ): Promise<DocumentSnapshot<GeneratedInvoiceData>> {
    return await this.query.generatedInvoice(id).get();
  }

  async setGeneratedInvoice(id: string, data: GeneratedInvoiceData) {
    const cleanData = (data.filteredMatters as any[]).map((matter: any) => {
      return matter;
    });
    await this.query.generatedInvoice(id).set({ filteredMatters: cleanData });
  }

  // -------------------------------------------------------------------------
  // humans

  async addHuman(data: HumanData): Promise<DocumentReference<HumanData>> {
    return await this.query.humans().add(data);
  }

  async getHumans(
    where: WhereClause<HumanData>[] = []
  ): Promise<QuerySnapshot<HumanData>> {
    return await this.query.humans(where, []).get();
  }

  async getHuman(id: string): Promise<DocumentSnapshot<HumanData>> {
    return await this.query.human(id).get();
  }

  async updateHuman(id: string, data: Partial<HumanData>) {
    await this.#updateDocument(this.query.human(id), data);
  }

  async deleteHuman(id: string) {
    await this.query.human(id).delete();
  }

  // invoices methods

  /**
   * @returns A `DocumentReference` object.
   */
  async addInvoice(data: InvoiceData): Promise<DocumentReference<InvoiceData>> {
    return await this.query.invoices().add(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getInvoices(
    where: WhereClause<InvoiceData>[] = []
  ): Promise<QuerySnapshot<InvoiceData>> {
    return await this.query.invoices(where, []).get();
  }

  async setInvoice(id: string, data: InvoiceData): Promise<void> {
    await this.query.invoices().doc(id).set(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @param {*} observer The function called when relevant invoices change.
   * @returns The 'unsubscribe' function.
   */
  subscribeToInvoicesChanges(
    where: WhereClause<InvoiceData>[],
    observer: (snapshot: QuerySnapshot<InvoiceData>) => void
  ): () => void {
    const unsubscribe = this.query.invoices(where, []).onSnapshot(observer);
    const subscriptionName = 'invoices';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // knowledgeBasePage methods

  async addKnowledgeBasePage(
    data: KnowledgeBasePageData
  ): Promise<DocumentReference<KnowledgeBasePageData>> {
    return await this.query.knowledgeBasePages().add(data);
  }

  async getKnowledgeBasePages(): Promise<QuerySnapshot<KnowledgeBasePageData>> {
    return await this.query.knowledgeBasePages([], []).get();
  }

  subscribeToKnowledgeBasePagesChanges(
    observer: (snapshot: QuerySnapshot<KnowledgeBasePageData>) => void,
    where?: WhereClause<KnowledgeBasePageData>[],
    orderBy?: OrderByClause<KnowledgeBasePageData>[]
  ): () => void {
    const unsubscribe = this.query
      .knowledgeBasePages(where, orderBy)
      .onSnapshot(observer);
    const subscriptionName = 'knowledgeBasePages';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  async updateKnowledgeBasePage(
    id: string,
    data: Partial<KnowledgeBasePageData>
  ) {
    await this.#updateDocument(this.query.knowledgeBasePage(id), data);
  }

  async deleteKnowledgeBasePage(id: string) {
    await this.query.knowledgeBasePage(id).delete();
  }

  // matters methods

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getMatters(
    where: WhereClause<MatterData>[] = [],
    orderBy: OrderByClause<MatterData>[] = []
  ): Promise<QuerySnapshot<MatterData>> {
    return await this.query.matters(where, orderBy).get();
  }

  /**
   * @param {*} id The matter's ID.
   * @returns A `DocumentSnapshot` object.
   */
  async getMatter(id: string): Promise<DocumentSnapshot<MatterData>> {
    return await this.query.matter(id).get();
  }

  /**
   * Subscribes to changes in matter document.
   *
   * @param observer The function called when the specified matter changes.
   * @returns The 'unsubscribe' function.
   */
  subscribeToMatterChanges(
    id: string,
    observer: (snapshot: DocumentSnapshot<MatterData>) => void
  ): () => void {
    const unsubscribe = this.query.matter(id).onSnapshot(observer);
    const subscriptionName = `matters/${id}`;
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  /**
   * Subscribes to changes in matters collection.
   *
   * @param observer The function called when relevant matters change.
   * @param where An array of [fieldPath, opStr, value] tuples to filter matters.
   * @param orderBy An array of [fieldPath, directionStr] tuples to order matters.
   * @returns The 'unsubscribe' function.
   */
  subscribeToMattersChanges(
    observer: (snapshot: QuerySnapshot<MatterData>) => void,
    where?: WhereClause<MatterData>[],
    orderBy?: OrderByClause<MatterData>[]
  ): () => void {
    const unsubscribe = this.query.matters(where, orderBy).onSnapshot(observer);
    const subscriptionName = 'matters';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  async updateMatter(id: string, data: Partial<MatterData>) {
    await this.#updateDocument(this.query.matter(id), data);
  }

  async deleteMatter(id: string) {
    await this.query.matter(id).delete();
  }

  // matters/{id}/contactsWithRoles methods

  /**
   *
   * @param {*} matterId Self-explanatory.
   * @param {*} contactDataArray An array of the objects that should be added
   *   to the new matter's contactsWithRoles. Each object should have form
   *   `{ contact: DocumentReference, role: string }`.
   */
  async addMatterContacts(
    matterId: string,
    contactDataArray: ContactWithRoleData[]
  ): Promise<CollectionReference<ContactWithRoleData>> {
    const query = this.query.matterContacts(matterId);
    const batch = this.firestore.batch();
    for (const contactData of contactDataArray) {
      batch.set(query.doc(), contactData);
    }
    await batch.commit();
    return query;
  }

  /**
   * @returns A `QuerySnapshot` object.
   */
  async getMatterContacts(
    matterId: string
  ): Promise<QuerySnapshot<ContactWithRoleData>> {
    return await this.query.matterContacts(matterId).get();
  }

  subscribeToMatterContactsChanges(
    matterId: string,
    observer: (snapshot: QuerySnapshot<ContactWithRoleData>) => void
  ): () => void {
    const unsubscribe = this.query
      .matterContacts(matterId)
      .onSnapshot(observer);
    const subscriptionName = `matters/${matterId}/contacts`;
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // matters/{id}/documents methods

  /**
   *
   * @param {*} matterId
   * @param {*} documentData
   * @returns A `DocumentReference` object.
   */
  async addMatterDocument(
    matterId: string,
    documentData: MatterDocumentData
  ): Promise<DocumentReference<MatterDocumentData>> {
    return await this.query.matterDocuments(matterId).add(documentData);
  }

  /**
   *
   * @param {*} matterId
   * @returns A `QuerySnapshot` object.
   */
  async getMatterDocuments(
    matterId: string
  ): Promise<QuerySnapshot<MatterDocumentData>> {
    return await this.query.matterDocuments(matterId).get();
  }

  /**
   * @param {*} matterId Self-explanatory
   * @param {*} orderDocumentsBy An array of [fieldPath, directionStr] tuples.
   * @param {*} observer The function called when relevant documents change.
   * @returns The 'unsubscribe' function.
   */
  subscribeToMatterDocumentsChanges(
    matterId: string,
    orderDocumentsBy: OrderByClause<MatterDocumentData>[],
    observer: (snapshot: QuerySnapshot<MatterDocumentData>) => void
  ): () => void {
    const unsubscribe = this.query
      .matterDocuments(matterId, [], orderDocumentsBy)
      .onSnapshot(observer);
    const subscriptionName = `matters/${matterId}/documents`;
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // matters/{id}/notes methods

  /**
   * @param {*} matterId Self-explanatory
   * @param {*} orderNotesBy An array of [fieldPath, directionStr] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getMatterNotes(
    matterId: string,
    orderNotesBy: OrderByClause<NoteData>[] = []
  ): Promise<QuerySnapshot<NoteData>> {
    return await this.query.matterNotes(matterId, [], orderNotesBy).get();
  }

  /**
   *
   * @param {*} matterId
   * @param {*} data
   * @returns A `DocumentReference` object.
   */
  async addMatterNote(
    matterId: string,
    data: NoteData
  ): Promise<DocumentReference<NoteData>> {
    return await this.query.matterNotes(matterId).add(data);
  }

  async deleteMatterNote(matterId: string, noteId: string): Promise<void> {
    await this.query.matterNotes(matterId).doc(noteId).delete();
  }

  /**
   * @param {*} matterId Self-explanatory
   * @param {*} orderNotesBy An array of [fieldPath, directionStr] tuples.
   * @param {*} observer The function called when relevant notes change.
   * @returns The 'unsubscribe' function.
   */
  subscribeToMatterNotesChanges(
    matterId: string,
    orderNotesBy: OrderByClause<NoteData>[],
    observer: (snapshot: QuerySnapshot<NoteData>) => void
  ): () => void {
    const unsubscribe = this.query
      .matterNotes(matterId, [], orderNotesBy)
      .onSnapshot(observer);
    const subscriptionName = `matters/${matterId}/notes`;
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // `organizations` methods

  async addOrganization(
    data: OrganizationData
  ): Promise<DocumentReference<OrganizationData>> {
    return await this.query.organizations().add(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getOrganizations(
    where: WhereClause<OrganizationData>[] = []
  ): Promise<QuerySnapshot<OrganizationData>> {
    return await this.query.organizations(where, []).get();
  }

  /**
   * @returns A `DocumentSnapshot` object.
   */
  async getOrganization(
    id: string
  ): Promise<DocumentSnapshot<OrganizationData>> {
    return await this.query.organization(id).get();
  }

  async deleteOrganization(id: string) {
    await this.query.organization(id).delete();
  }

  // `tasks` methods

  async addTask(data: TaskData): Promise<DocumentReference<TaskData>> {
    return await this.query.tasks().add(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getTasks(
    where: WhereClause<TaskData>[] = [],
    orderBy: OrderByClause<TaskData>[] = []
  ): Promise<QuerySnapshot<TaskData>> {
    return await this.query.tasks(where, orderBy).get();
  }

  async getTask(id: string): Promise<DocumentSnapshot<TaskData>> {
    return await this.query.task(id).get();
  }

  async updateTask(id: string, data: Partial<TaskData>) {
    await this.#updateDocument(this.query.task(id), data);
  }

  async updateTasks(data: Partial<TaskData>, where?: WhereClause<TaskData>[]) {
    const tasks = await this.query.tasks(where).get();
    const batch = this.firestore.batch();
    for (const task of tasks.docs) {
      batch.update(task.ref, cleanDataToFirestore(data));
    }
    await batch.commit();
  }

  async deleteTask(id: string) {
    await this.query.task(id).delete();
  }

  /**
   * @param {*} observer The function called when relevant tasks change.
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @param {*} orderBy An array of [fieldPath, directionStr] tuples.
   * @returns The 'unsubscribe' function.
   */
  subscribeToTasksChanges(
    observer: (snapshot: QuerySnapshot<TaskData>) => void,
    where?: WhereClause<TaskData>[],
    orderBy?: OrderByClause<TaskData>[]
  ): () => void {
    const unsubscribe = this.query.tasks(where, orderBy).onSnapshot(observer);
    const subscriptionName = 'tasks';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // `timeEntries` methods

  async addTimeEntry(
    data: TimeEntryData
  ): Promise<DocumentReference<TimeEntryData>> {
    return await this.query.timeEntries().add(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getTimeEntries(
    where: WhereClause<TimeEntryData>[] = []
  ): Promise<QuerySnapshot<TimeEntryData>> {
    return await this.query.timeEntries(where).get();
  }

  async updateTimeEntry(
    id: string,
    data: Partial<TimeEntryData>
  ): Promise<void> {
    this.#updateDocument(this.query.timeEntry(id), data);
  }

  async deleteTimeEntry(id: string) {
    await this.query.timeEntry(id).delete();
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @param {*} orderBy An array of [fieldPath, directionStr] tuples.
   * @param {*} observer The function called when relevant timeEntries change.
   * @returns The 'unsubscribe' function.
   */
  subscribeToTimeEntriesChanges(
    where: WhereClause<TimeEntryData>[],
    orderBy: OrderByClause<TimeEntryData>[],
    observer: (snapshot: QuerySnapshot<TimeEntryData>) => void
  ): () => void {
    const unsubscribe = this.query
      .timeEntries(where, orderBy)
      .onSnapshot(observer);
    this.#incrementSubscriptionCount('timeEntries');
    return () => {
      this.#decrementSubscriptionCount('timeEntries');
      unsubscribe();
    };
  }

  // `paySheets` methods

  /**
   * @param {*} data
   * @returns A `DocumentReference` object.
   */

  async addPaySheet(
    data: PaySheetData
  ): Promise<DocumentReference<PaySheetData>> {
    return await this.firestore
      .collection(`firms/${this.firmId}/paySheets`)
      .withConverter<PaySheetData>({
        toFirestore: (data) => data as Document,
        fromFirestore: (snap) => snap.data() as PaySheetData,
      })
      .add(data);
  }

  async getPaySheets(
    where: WhereClause<PaySheetData>[] = []
  ): Promise<QuerySnapshot<PaySheetData>> {
    return await this.query.paySheets(where).get();
  }

  async updatePaySheet(
    paySheetId: string,
    data: Partial<PaySheetData>
  ): Promise<void> {
    const paySheetRef = this.firestore
      .collection('firms')
      .doc(this.firmId)
      .collection('paySheets')
      .doc(paySheetId)
      .withConverter<PaySheetData>({
        toFirestore: (data) => data as Document,
        fromFirestore: (snap) => snap.data() as PaySheetData,
      });

    await this.#updateDocument(paySheetRef, data);
  }

  // `transactions` methods

  /**
   * @param {*} data
   * @returns A `DocumentReference` object.
   */
  async addTransaction(
    data: TransactionData
  ): Promise<DocumentReference<TransactionData>> {
    return await this.query.transactions().add(data);
  }

  /**
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @returns A `QuerySnapshot` object.
   */
  async getTransactions(
    where: WhereClause<TransactionData>[] = []
  ): Promise<QuerySnapshot<TransactionData>> {
    return await this.query.transactions(where, []).get();
  }

  async updateTransaction(
    transactionId: string,
    data: Partial<TransactionData>
  ): Promise<void> {
    const transactionRef = this.firestore
      .collection('firms')
      .doc(this.firmId)
      .collection('transactions')
      .doc(transactionId);

    await this.#updateDocument(transactionRef, data);
  }

  async getUserSpecificTransactionData(transactionId: string, userId: string) {
    return db
      .collection('transactions')
      .doc(transactionId)
      .collection('userSpecificData')
      .doc(userId)
      .get();
  }

  async updateUserSpecificTransactionData(
    transactionId: string,
    userId: string,
    data: any
  ) {
    return db
      .collection('transactions')
      .doc(transactionId)
      .collection('userSpecificData')
      .doc(userId)
      .set(data, { merge: true });
  }

  async deleteTransaction(id: string): Promise<void> {
    await this.query.transaction(id).delete();
  }

  // `users` methods

  async getUsers(
    where: WhereClause<UserData>[] = [],
    orderBy: OrderByClause<UserData>[] = []
  ): Promise<QuerySnapshot<UserData>> {
    return await this.query.users(where, orderBy).get();
  }

  async getUser(id: string): Promise<DocumentSnapshot<UserData>> {
    return await this.query.user(id).get();
  }

  async updateUser(id: string, data: Partial<UserData>) {
    await this.#updateDocument(this.query.user(id), data);
  }

  /**
   * @param {*} observer The function called when relevant users change.
   * @param {*} where An array of [fieldPath, opStr, value] tuples.
   * @param {*} orderBy An array of [fieldPath, directionStr] tuples.
   * @returns The 'unsubscribe' function.
   */
  subscribeToUsersChanges(
    observer: (snapshot: QuerySnapshot<UserData>) => void,
    where?: WhereClause<UserData>[],
    orderBy?: OrderByClause<UserData>[]
  ): () => void {
    const unsubscribe = this.query.users(where, orderBy).onSnapshot(observer);
    const subscriptionName = 'users';
    this.#incrementSubscriptionCount(subscriptionName);
    return () => {
      unsubscribe();
      this.#decrementSubscriptionCount(subscriptionName);
    };
  }

  // ---------------------------------------------------------------------------
  // Logging
  // ---------------------------------------------------------------------------

  readonly activeSubscriptionCounts: { [name: string]: number } = {};

  logSubscriptionCount() {
    console.table(this.activeSubscriptionCounts);
  }

  #incrementSubscriptionCount(name: string) {
    this.activeSubscriptionCounts[name] = this.activeSubscriptionCounts[name]
      ? this.activeSubscriptionCounts[name] + 1
      : 1;
  }

  #decrementSubscriptionCount(name: string) {
    this.activeSubscriptionCounts[name] = this.activeSubscriptionCounts[name]
      ? this.activeSubscriptionCounts[name] - 1
      : 0;
    if (this.activeSubscriptionCounts[name] == 0) {
      delete this.activeSubscriptionCounts[name];
    }
  }

  async #updateDocument<T>(ref: DocumentReference<T>, data: Partial<T>) {
    await ref.update(cleanDataToFirestore(data));
  }
}
