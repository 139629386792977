import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { analytics, firebase, storage } from '../firebaseConfig';
import SignatureCanvas from 'react-signature-canvas';
import './EngagementLetter.css';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import Lottie from 'lottie-react';
import successAnimation from '../assets/success-animation.json';
import { createPdfFromHtml } from './createPdfFromHtml';
import CircularProgress from '@mui/material/CircularProgress';
import { captureException } from '@sentry/react';
import { LoadingMessage } from './LoadingMessage';
import { logEvent } from 'firebase/analytics';

interface EngagementLetterParams {
  letterId: string;
  matterId: string;
  formattedName: string;
  firmId: string;
}

const EngagementLetter = () => {
  const { letterId, matterId, formattedName, firmId } =
    useParams<EngagementLetterParams>();
  const [letterData, setLetterData] = useState<
    { logoUrl: string; text: string; textBlocks: string[] } | undefined
  >();
  const [signatureStatus, setSignatureStatus] = useState<
    'unsigned' | 'signed' | 'saving' | 'saved'
  >('unsigned');
  const abortControllerRef = useRef<AbortController | undefined>();
  const sigCanvas = useRef<SignatureCanvas | undefined>();
  const [timeStamp, setTimeStamp] = useState<string | undefined>();
  const [loading, setLoading] = useState(true); // State for loading status
  const [pdfUrl, setPdfUrl] = useState<string | undefined>();
  const [pctSaved, setPctSaved] = useState<number>(0);

  let specificClientName = 'Client'; // Default value

  // Check if formattedName is defined before trying to split it
  if (formattedName) {
    const [formattedFirstName, formattedLastName] = formattedName.split('_');
    if (formattedFirstName && formattedLastName) {
      specificClientName = `${formattedFirstName} ${formattedLastName}`;
    }
  }

  const analyticsPayload = useMemo(() => {
    return {
      signatureStatus: signatureStatus,
      letterId: letterId,
      matterId: matterId,
      formattedName: formattedName,
      firmId: firmId,
    };
  }, [signatureStatus, letterId, matterId, formattedName, firmId]);

  useEffect(() => {
    logEvent(
      analytics,
      'engagement_letter_signature_status_change',
      analyticsPayload
    );
  }, [signatureStatus]);

  const handleClearClick = () => {
    logEvent(
      analytics,
      'engagement_letter_signature_clear_click',
      analyticsPayload
    );

    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignatureStatus('unsigned');
    }
  };

  const handleSaveSignatureClick = async () => {
    logEvent(
      analytics,
      'engagement_letter_signature_save_click',
      analyticsPayload
    );

    if (signatureStatus != 'signed') {
      return;
    }
    setSignatureStatus('saving');
    const newTimeStamp = new Date().toLocaleString();
    setTimeStamp(newTimeStamp);

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current!.signal;
    setPctSaved(0);

    const origLetterElement = document.getElementById('engagementLetter');

    const progressAfterRender = 80; // 80% done when PDF rendering is finished.
    const progressAfterUpload = 90; // 90% done when letter has been uploaded.
    const progressAfterSave = 99; // 99% done when letter info is saved to firestore
    const handleRenderProgressChange = (progress: number) => {
      if (signal.aborted) {
        return;
      }
      setPctSaved(progressAfterRender * progress);
    };

    const pdf = await createPdfFromHtml(
      origLetterElement!.children,
      { top: 50, right: 50, bottom: 50, left: 50 },
      1,
      handleRenderProgressChange
    );
    logEvent(analytics, 'engagement_letter_pdf_rendered', analyticsPayload);

    if (signal.aborted) {
      return;
    }

    const blob = pdf.output('blob');
    const letterStorageRef = storage.ref(`signedLetters/${letterId}.pdf`);
    const snapshot = await letterStorageRef.put(blob);
    logEvent(
      analytics,
      'engagement_letter_pdf_saved_to_bucket',
      analyticsPayload
    );
    if (signal.aborted) {
      return;
    }
    const downloadUrl = await snapshot.ref.getDownloadURL();
    if (signal.aborted) {
      return;
    }
    setPctSaved(progressAfterUpload);

    const saveSignatureFunction = firebase
      .functions()
      .httpsCallable('saveEngagementLetterSignature');
    await saveSignatureFunction({
      firmId: firmId,
      matterId: matterId,
      letterUrl: downloadUrl,
      timestamp: newTimeStamp,
    });
    logEvent(analytics, 'engagement_letter_saved_to_firestore', {
      ...analyticsPayload,
      downloadUrl,
      timestamp: newTimeStamp,
    });
    if (signal.aborted) {
      return;
    }
    setPctSaved(progressAfterSave);
    setPdfUrl(downloadUrl);
    setSignatureStatus('saved');
  };

  const onEnd = () => {
    if (sigCanvas.current) {
      const isCanvasBlank = sigCanvas.current.isEmpty();
      setSignatureStatus(isCanvasBlank ? 'unsigned' : 'signed');
    }
  };

  const handleSaveACopyClick = () => {
    logEvent(analytics, 'engagement_letter_save_copy_click', {
      ...analyticsPayload,
      pdfUrl,
    });
    open(pdfUrl, '_blank');
  };

  useEffect(() => {
    const getLetterFunction = firebase
      .functions()
      .httpsCallable('getEngagementLetter');
    getLetterFunction({ firmId: firmId, letterId: letterId })
      .then((response) => {
        if (response) {
          const blocks = (response.data.text as string).split('\n\n');
          response.data.textBlocks = blocks;
          setLetterData(response.data);
        } else {
          console.error('No engagement letter found.');
          captureException(
            new Error('No engagement letter found.'),
            (scope) => {
              scope.setExtras({ ...analyticsPayload });
              return scope;
            }
          );
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        captureException(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [firmId, letterId]);

  if (loading) {
    return <LoadingMessage />;
  }

  const handleCancelClick = () => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = undefined;
    setPctSaved(0);
    setSignatureStatus('signed');
  };

  return (
    <>
      <div className='full-page-container'>
        <div
          id='engagementLetter'
          style={{ margin: '50px', width: '750px', backgroundColor: '#FFFFFF' }}
        >
          <img
            src={letterData?.logoUrl ?? ''}
            alt='Law Firm Logo'
            className='responsive-logo'
          />
          <h1>Engagement Letter</h1>
          {(letterData?.textBlocks ?? []).map((block, idx) => (
            <p
              key={idx}
              style={{ whiteSpace: 'pre-line', breakBefore: 'page' }}
            >
              {block}
            </p>
          ))}
          {['unsigned', 'signed'].includes(signatureStatus) ? (
            <div>
              <div className='signature-field'>
                <SignatureCanvas
                  penColor='black'
                  backgroundColor='#fff'
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: 'sig-canvas',
                  }}
                  ref={sigCanvas as any}
                  onEnd={onEnd}
                />
              </div>
              <button
                className='button outline-button'
                onClick={handleClearClick}
              >
                Clear Signature
              </button>
              <button
                onClick={() => {
                  handleSaveSignatureClick();
                }}
                disabled={signatureStatus != 'signed'}
              >
                Save Signature
              </button>
            </div>
          ) : (
            <div>
              <div className='signature-field-signed'>
                <SignatureCanvas
                  backgroundColor='#fff'
                  penColor='black'
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: 'sig-canvas',
                  }}
                  ref={sigCanvas as any}
                  // disabled={true}
                />
              </div>
              <p>
                Signed by {specificClientName} on {timeStamp}
              </p>
            </div>
          )}
        </div>
        <Dialog open={['saving', 'saved'].includes(signatureStatus)}>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '360px',
              minHeight: '360px',
            }}
          >
            {signatureStatus == 'saving' ? (
              <div
                style={{
                  height: '166px',
                  width: '166px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgressWithLabel value={pctSaved} />
              </div>
            ) : (
              <Lottie
                animationData={successAnimation}
                style={{ height: '166px' }}
                loop={false}
              />
            )}
            <Typography
              variant='h4'
              align='center'
              sx={{ marginTop: '-24px', marginBottom: '16px' }}
            >
              {signatureStatus == 'saving' ? 'Saving' : 'Success!'}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant='body1'
                align='center'
                sx={{
                  marginBottom: '24px',
                  color: '#666',
                  whiteSpace: 'pre-line',
                }}
              >
                {signatureStatus == 'saving'
                  ? `This should only take a moment.`
                  : `We've received your signed engagement letter and will reach out soon with next steps.`}
              </Typography>
            </div>
            {signatureStatus == 'saving' ? (
              <button
                style={{ fontWeight: '400' }}
                className='outline-button'
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            ) : (
              <button
                style={{ fontWeight: '400' }}
                onClick={handleSaveACopyClick}
              >
                Save a PDF copy
              </button>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default EngagementLetter;

function CircularProgressWithLabel({ value }: { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant='determinate'
        value={value}
        sx={{ color: '#79f655' }}
        size={59}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          sx={{ color: '#000' }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
